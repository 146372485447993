import { useState } from "react";
import { Button } from "../../components";
import FeeEntry from "./FeeEntry";
import { AfriexActions, TransactionFee } from "../../types";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import { AfriexPermissions } from "../../types";
import ConfirmationModal from "../../components/CustomModal/ConfirmationModal";
import FeeEntryModal from "./FeeEntryModal";

interface Props {
  overrides: TransactionFee[];
  onSave: (fee: TransactionFee) => void;
  onDelete: (fee: TransactionFee, index: number) => void;
}

interface OverrideToDelete extends TransactionFee {
  index: number;
}

const FeeOverrideSection = ({ overrides, onSave, onDelete }: Props) => {
  const handleAdd = () => {
    setEditingFee({ type: "override" });
  };
  const [editingFee, setEditingFee] = useState<TransactionFee>();
  const [selectedOverride, setSelectedOverride] = useState<OverrideToDelete>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openOverrideDelete = (override: TransactionFee, index: number) => {
    setSelectedOverride({ ...override, index });
    setIsConfirmModalOpen(true);
  };

  return (
    <div className="section">
      <div className="flex flex-row justify-between">
        <h1 className="text-xl font-medium">Fee Overrides</h1>

        <PermissionsProvider
          permission={AfriexPermissions.FEES_PAGE}
          action={AfriexActions.CLICK_FEES_ADD_OVERRIDE}
        >
          <Button colorScheme="cyan" onClick={handleAdd}>
            Add
          </Button>
        </PermissionsProvider>
      </div>

      <div className="mb-8 p-4 rounded-md border-solid border-4 border-gray-100 w-3/4">
        <p>
          Create fees that will override the base fee. Overrides take the
          following priority:
        </p>
        <ul className="flex">
          <li>Exact Currency &gt; </li>
          <li>Destination Currency &gt; </li>
          <li>Payment Method on transaction &gt; </li>
          <li>Base Fee</li>
        </ul>
      </div>

      {overrides.map((override, index) => (
        <FeeEntry
          key={JSON.stringify(Object.values(override))}
          fee={override}
          onEdit={(newOverride) => {
            setEditingFee(newOverride);
          }}
          onDelete={() => openOverrideDelete(override, index)}
        />
      ))}

      <FeeEntryModal
        isOpen={!!editingFee}
        onClose={() => setEditingFee(undefined)}
        fee={editingFee}
        onSave={(newOverride) => {
          setEditingFee(undefined);
          if (!newOverride) {
            // don't save to list
            return;
          }
          onSave?.(newOverride);
        }}
      />

      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        label="Are you sure you want to delete this fee?"
        cancelAction={() => setIsConfirmModalOpen(false)}
        cancelActionLabel="No"
        confirmAction={() => {
          setIsLoading(true);
          onDelete(
            selectedOverride as TransactionFee,
            selectedOverride?.index as number
          );
          setTimeout(() => {
            setIsLoading(false);
            setIsConfirmModalOpen(false);
          }, 1000);
        }}
        confirmActionLabel="Yes"
        loadingLabel="Deleting..."
        loading={isLoading}
        title="Confirm to delete"
      />
    </div>
  );
};
export default FeeOverrideSection;
