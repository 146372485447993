import { DownloadIcon } from "@heroicons/react/outline";
import { Button } from "..";

const GenerateOnePageButton = ({
  fileName,
  label,
}: {
  fileName: string;
  label: string;
}) => {
  const generatePDF = async () => {
    const html2pdf = await require("html2pdf.js");
    const elements = Array.from(
      document.getElementsByClassName("statement-parent")
    ) as HTMLElement[];

    if (!elements || elements.length === 0) {
      console.error("No elements with class 'statement-parent' found.");
      return;
    }

    const curTime = new Date().getTime();
    const name = `${curTime}_${fileName ?? ""}_afriex.pdf`;

    // Combine all statement-parent elements into one container
    const container = document.createElement("div");
    elements.forEach((parent) => {
      const contentElement = parent.querySelector(".content");
      if (contentElement) {
        const clone = contentElement.cloneNode(true) as HTMLElement;
        clone.classList.remove("w-680");
        clone.classList.remove("border-receipt-line");
        clone.classList.remove("border");
        container.appendChild(clone);
      }
    });

    const pdfOptions = {
      margin: 10,
      filename: name,
      image: { type: "png" },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "px",
        hotfixes: ["px_scaling"],
      },
      pagebreak: {
        mode: "avoid-all",
        after: ".statement-parent",
      },
    };

    html2pdf().from(container).set(pdfOptions).save();
  };

  return (
    <Button
      onClick={generatePDF}
      colorScheme="cyan"
      variant="outline"
      leftIcon={<DownloadIcon className="h-5 w-5" />}
      className="bg-indigo-200"
    >
      {label}
    </Button>
  );
};

export default GenerateOnePageButton;
