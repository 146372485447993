import React, { ReactElement } from "react";
import * as formatDate from "../../../utils/dateFormatter";
import { AfriexActions, AfriexPermissions, AfriexPerson } from "../../../types";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
import { getUXCamUserSessionURL } from "../../../utils/getUXCamUserSessionURL";
import VirtualAccountInfo from "../../KYC/Details/VirtualAccountInfo";
import useKYCDetails from "../../KYC/Details/useKYCDetails";
import { isEmpty } from "lodash";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { capitalizeWords } from "../../../helpers/dashboard";
import { getEarliestLocation } from "../../../constants/formatters";
import { IPDetailsLink } from "../../../components/TransactionDetail/TransactionDetail";

export interface UserProfileProps {
  user: AfriexPerson;
  type?: "user" | "kyc";
  showUserID?: boolean;
  showPhone?: boolean;
  showPhoneVerified?: boolean;
  showReferredBy?: boolean;
  showSecurityEnabled?: boolean;
  showKYCStatus?: boolean;
  showUXCamSessions?: boolean;
  showUsername?: boolean;
  showDateOpened?: boolean;
  showDeviceId?: boolean;
  showLastLogin?: boolean;
  showEmail?: boolean;
  showViewTransactions?: boolean;
  showVirtualAccount?: boolean;
  showStripeUser?: boolean;
  showRewardPoints?: boolean;
  showInteracEmails?: boolean;
  showRegistrationIP?: boolean;
}

const UserProfile = ({
  user,
  type = "user",
  showUserID,
  showPhone,
  showPhoneVerified,
  showReferredBy,
  showSecurityEnabled,
  showKYCStatus,
  showUXCamSessions,
  showUsername,
  showDateOpened,
  showDeviceId,
  showLastLogin,
  showEmail,
  showViewTransactions,
  showVirtualAccount,
  showStripeUser,
  showRewardPoints,
  showInteracEmails,
  showRegistrationIP,
}: UserProfileProps): ReactElement => {
  const { virtualAccountInfo } = useKYCDetails(user?.id);
  const interac = user.externalAccounts?.juicyway;
  const earliestLocation = getEarliestLocation(user.locations);
  return (
    <div>
      {/* Email */}
      {showEmail && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Account Email</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.currentEmail}
          </p>
        </div>
      )}

      {/* Username */}
      {showUsername && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Account Username</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.name?.userName}
          </p>
        </div>
      )}

      {/* User ID */}
      {showUserID && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">User ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <span className="mr-3">{user.id}</span>
            {type !== "user" && (
              <Link
                to={`/users/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="ghost" className="no-padding no-margin">
                  View User Profile
                </Button>
              </Link>
            )}
          </p>
        </div>
      )}

      {/* Phone */}
      {showPhone && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Phone Number</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user?.currentPhone}
          </p>
        </div>
      )}

      {/* Phone Verified */}
      {showPhoneVerified && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Phone Verified</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {String(Boolean(user?.isPhoneVerified))}
          </p>
        </div>
      )}

      {/* Referred By */}
      {showReferredBy && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Referred By</p>
          {user?.referralInfo?.referrerId ? (
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {user?.referralInfo?.referrerId} (
              {user?.referralInfo?.referrerUserName})
            </p>
          ) : (
            <p className="text-sm font-medium text-gray-700 w-1/2">None</p>
          )}
        </div>
      )}

      {/* Security Enabled */}
      {showSecurityEnabled && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Security Enabled</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.isSecurityFlagged?.toString()}
          </p>
        </div>
      )}

      {/* KYC Status */}
      {showKYCStatus && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">KYC Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <span className="mr-3">{user?.kyc?.status?.toUpperCase()}</span>
            <PermissionsProvider
              permission={AfriexPermissions.USERS_PAGE}
              action={AfriexActions.CLICK_VIEW_USER_KYC_DETAILS}
            >
              {type !== "kyc" && (
                <Link
                  to={`/kyc/${user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="ghost" className="no-padding no-margin">
                    View KYC details
                  </Button>
                </Link>
              )}
            </PermissionsProvider>
          </p>
        </div>
      )}

      {/* UXCam Sessions */}
      {showUXCamSessions && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">UXCam Sessions</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            <Link
              to={getUXCamUserSessionURL(user.id)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="ghost" className="no-padding no-margin">
                Click to view UXCam Sessions
              </Button>
            </Link>
          </p>
        </div>
      )}

      {/* Date Opened */}
      {showDateOpened && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date Opened</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(user?.createdAt?.toString() ?? "")}
          </p>
        </div>
      )}

      {/* Device ID */}
      {showDeviceId && (
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Device ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {user.currentDeviceInfo?.deviceId ||
              (Array.isArray(user.devices) &&
              user.devices.filter(Boolean).length > 0
                ? user.devices.filter(Boolean)[
                    user.devices.filter(Boolean).length - 1
                  ]?.deviceId || "N/A"
                : "N/A")}
          </p>
        </div>
      )}

      {/* Registration IP */}
      {showRegistrationIP && earliestLocation?.ipAddress && (
        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.VIEW_USER_REGISTRATION_IP}
        >
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Registration IP Address</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {earliestLocation?.ipAddress}{" "}
              <IPDetailsLink
                ipAddress={earliestLocation?.ipAddress as string}
              />
            </p>
          </div>
        </PermissionsProvider>
      )}

      {/* Last Login */}
      {showLastLogin &&
        (user.lastLoggedInAt ? (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Last Login</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {formatDate.dateTimeInTimeZone(user.lastLoggedInAt)}
            </p>
          </div>
        ) : null)}

      {/* View Transactions */}
      <PermissionsProvider
        permission={AfriexPermissions.TRANSACTIONS_PAGE}
        action={AfriexActions.CLICK_VIEW_USER_TRANSACTIONS}
      >
        {showViewTransactions && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Transactions</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              <Link
                to={`/transactions/user/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="ghost" className="no-padding no-margin">
                  View Transactions
                </Button>
              </Link>
            </p>
          </div>
        )}
      </PermissionsProvider>

      <PermissionsProvider
        permission={AfriexPermissions.USERS_PAGE}
        action={AfriexActions.VIEW_USER_INTERAC_EMAILS}
      >
        {showInteracEmails &&
          interac?.emails &&
          interac?.emails?.length > 0 && (
            <div className="mb-2 py-2 px-3 flex gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Interac Linked Emails</p>
              <p className="text-sm font-medium text-gray-700 w-1/2 overflow-hidden whitespace-normal">
                {interac.emails.join(", ")}
              </p>
            </div>
          )}
      </PermissionsProvider>

      {showRewardPoints &&
        user?.rewardInfo &&
        user.rewardInfo.rewardPoints > 0 && (
          <PermissionsProvider
            permission={AfriexPermissions.USERS_PAGE}
            action={AfriexActions.VIEW_USER_REWARD_POINTS}
          >
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Reward Tier</p>
              <p className="flex text-sm font-medium text-gray-700 w-1/2 gap-2">
                <span>{capitalizeWords(user.rewardInfo.rewardTier)}</span>
                <span className="text-sm font-semibold text-blue-700">
                  ({user.rewardInfo.rewardPoints + " Points"})
                </span>
                <Link
                  to={`/transactions/rewards/${user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="ghost" className="no-padding no-margin">
                    View Reward Transactions
                  </Button>
                </Link>
              </p>
            </div>
          </PermissionsProvider>
        )}

      {showStripeUser && !isEmpty(user?.externalAccounts?.stripe) && (
        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.VIEW_USER_STRIPE_DETAILS}
        >
          <React.Fragment>
            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Stripe Customer ID</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.externalAccounts?.stripe?.customerId}
              </p>
            </div>

            <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
              <p className="text-slate-500 text-sm">Stripe Account ID</p>
              <p className="text-sm font-medium text-gray-700 w-1/2">
                {user?.externalAccounts?.stripe?.accountId}
              </p>
            </div>
          </React.Fragment>
        </PermissionsProvider>
      )}

      {showVirtualAccount && !isEmpty(virtualAccountInfo) && (
        <VirtualAccountInfo data={virtualAccountInfo} />
      )}
    </div>
  );
};

export default UserProfile;
