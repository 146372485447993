import { AfriexActions } from "../../../types/AfriexRoles";
import { AfriexPermissions } from "../../../types/AfriexRoles";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { OTCFiltersProps } from "./types";
import { Button, CustomSelect, DatePicker } from "../../../components";
import { getCurrencyOptions } from "../../../constants/formatters";
import { mapCountryListToCurrencies } from "../../../utils/mapCountryListToOptions";
import useCountryList from "../../../hooks/useCountryList";
import { XIcon } from "@heroicons/react/outline";
import OTCUpdateButton from "./OTCUpdateButton";

function OTCVolumeFilters({
  filtersToApply,
  currentFilters,
  clearFilters,
  selectAppliedFilters,
  handleApplyFilters,
  selectCurrentFilters,
}: OTCFiltersProps) {
  const { countryList } = useCountryList();
  const currencyOptions = mapCountryListToCurrencies(countryList ?? []);

  const fromDate = filtersToApply.fromDate
    ? new Date(filtersToApply.fromDate)
    : undefined;

  const toDate = filtersToApply.toDate
    ? new Date(filtersToApply.toDate)
    : undefined;
  return (
    <PermissionsProvider
      permission={AfriexPermissions.RATES_PAGE}
      action={AfriexActions.CLICK_RATES_SEARCH_FILTER}
    >
      <div className="mt-5 w-full">
        <div className="flex items-center justify-between">
          <div className="flex gap-2 items-center">
            <DatePicker
              selectedDate={fromDate}
              setSelectedDate={(date) =>
                selectAppliedFilters?.("fromDate", date)
              }
              placeholderText="Select From Date"
            />

            <DatePicker
              selectedDate={toDate}
              setSelectedDate={(date) => selectAppliedFilters?.("toDate", date)}
              placeholderText="Select To Date"
            />

            <div className="flex items-center gap-x-2 justify-end">
              <Button
                disabled={!fromDate || !toDate}
                onClick={handleApplyFilters}
                colorScheme="cyan"
              >
                <span className="text-xs">Search</span>
              </Button>

              <button
                onClick={clearFilters}
                title="Clear search"
                className="h-7 w-7 hidden md:flex rounded-md border border-gray-300 items-center justify-center hover:border-cyan-600 hover:text-cyan-600 group"
              >
                <XIcon className="h-5 w-5 text-gray-400 group-hover:text-cyan-600" />
              </button>
            </div>
          </div>

          <div className="flex items-center space-x-3">
            <CustomSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                selectCurrentFilters("currency", e.target.value)
              }
              value={currentFilters.currency}
              name="currency"
              options={getCurrencyOptions(currencyOptions)}
              placeholder="Filter by Currency"
            />

            <PermissionsProvider
              permission={AfriexPermissions.RATES_PAGE}
              action={AfriexActions.CLICK_RATES_OTC_UPLOAD}
            >
              <OTCUpdateButton />
            </PermissionsProvider>
          </div>
        </div>
      </div>
    </PermissionsProvider>
  );
}

export default OTCVolumeFilters;
