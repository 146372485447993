import { XIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { isEmpty } from "lodash";
import { useImperativeHandle, useState } from "react";
import {
  AfriexActions,
  AfriexPermissions,
  currencyPairOptions,
  FeePaymentMethod,
  limitTypeOptions,
  paymentMethodOptions,
  TransactionFee,
} from "../../types";
import { PermissionsProvider } from "../../components/common/PermissionsProvider";
import { CustomInput, CustomSelect } from "../../components";
import { doNothing } from "../../constants/formatters";

const editableInputClasses = "w-24 mt-2";
const readOnlyInputClasses = "w-24 border-0 bg-gray-200";
export type FeeEntryRef = {
  getFee: () => TransactionFee | undefined;
};
export type LimitType = "above" | "below" | "none";

/**
 * FeeEntry is a component that allows the user to edit or create a new fee override.
 * It is a controlled component, meaning that it requires a ref to be passed in order to
 * access the current fee value. The ref is passed in as an innerRef prop.
 */
const FeeEntry = ({
  layout = "col",
  fee,
  editStyle = "modal",
  onEdit,
  onDelete,
  type = "override",
  innerRef,
}: {
  layout?: "row" | "col";
  fee: TransactionFee;
  editStyle?: "modal" | "inline";
  onEdit?: (fee: TransactionFee) => void;
  onDelete?: (fee: TransactionFee) => void;
  type?: "override" | "default";
  innerRef?: React.Ref<FeeEntryRef>;
}) => {
  const [transactionType] = useState(fee?.transactionType);
  const [fixedValue, setFixedValue] = useState(
    fee?.baseValue?.toString() ?? ""
  );
  const [percentage, setPercentage] = useState(fee?.percentValue?.toString());
  const [maxFee, setMaxFee] = useState(fee?.maxFeeValue?.toString());
  const [currencyPair, setCurrencyPair] = useState<string | undefined>(
    fee?.currencyPair
  );
  const sourceCurrency = currencyPair?.split(":")[0];
  const destinationCurrency = currencyPair?.split(":")[1];
  const [selectedInstrument, setInstrument] = useState<
    FeePaymentMethod | undefined
  >(fee?.paymentMethod);
  const [limitType, setLimitType] = useState<LimitType>(
    fee?.limitType || "none"
  );
  const [limit, setLimit] = useState<number | undefined>(fee?.limit);
  const inputClasses =
    editStyle === "inline" ? editableInputClasses : readOnlyInputClasses;

  const disabled = editStyle === "modal";

  useImperativeHandle(
    innerRef,
    () => ({
      getFee: () =>
        ({
          paymentMethod: selectedInstrument,
          baseValue: !isEmpty(fixedValue) ? Number(fixedValue) : undefined,
          percentValue: !isEmpty(percentage) ? Number(percentage) : undefined,
          maxFeeValue: !isEmpty(maxFee) ? Number(maxFee) : undefined,
          limitType: limitType,
          currencyPair: !isEmpty(currencyPair) ? currencyPair : undefined,
          limit: limit,
          type,
        }) as TransactionFee,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedInstrument,
      fixedValue,
      percentage,
      maxFee,
      limitType,
      currencyPair,
      limit,
    ]
  );

  return (
    <div className="override-entry mb-4 w-full">
      <div className="overflow-x-auto hide-scrollbar">
        <div className={`flex flex-${layout === "row" ? "col" : "row"} gap-3`}>
          {type === "override" && (
            <div className="min-w-max">
              <CustomInput
                className={`${inputClasses} w-32`}
                disabled={disabled}
                name="transactionType"
                value={transactionType ?? "E2E"}
                onChange={doNothing}
                label="Transaction Type:"
              />
            </div>
          )}

          {type === "override" && (
            <CustomSelect
              name="instrument"
              disabled={disabled}
              className="min-w-max"
              bodyClassName={`${inputClasses}`}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedMethod =
                  e.target.value === "any"
                    ? ""
                    : Object.create(FeePaymentMethod)[e.target.value];
                setInstrument(selectedMethod);
              }}
              value={
                (selectedInstrument as string) === "" ||
                (selectedInstrument as string) === undefined
                  ? "any"
                  : selectedInstrument
              }
              options={paymentMethodOptions}
              label="Payment Method:"
            />
          )}

          {/* Currency Pair */}
          {type === "override" && (
            <div className="min-w-max">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Currency Pair:
              </label>

              <div className="flex items-center gap-2">
                <CustomSelect
                  name="sourceCurrency"
                  disabled={selectedInstrument !== undefined || disabled}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const destination = destinationCurrency;
                    const currencyPair = `${e.target.value}:${isEmpty(destination) ? "*" : destination}`;
                    const pair =
                      currencyPair.replace(/\*/g, "")?.length <= 1
                        ? undefined
                        : currencyPair;
                    setCurrencyPair(pair);
                  }}
                  value={sourceCurrency ?? "*"}
                  options={currencyPairOptions}
                  bodyClassName={`${inputClasses}`}
                />

                <CustomSelect
                  name="destinationCurrency"
                  disabled={selectedInstrument !== undefined || disabled}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const source = sourceCurrency;
                    const currencyPair = `${isEmpty(source) ? "*" : source}:${e.target.value}`;
                    const pair =
                      currencyPair.replace(/\*/g, "")?.length <= 1
                        ? undefined
                        : currencyPair;
                    setCurrencyPair(pair);
                  }}
                  value={destinationCurrency ?? "*"}
                  options={currencyPairOptions}
                  bodyClassName={`${inputClasses}`}
                />
              </div>
            </div>
          )}

          {/* Fixed */}
          <div className={`flex flex-${layout} min-w-max`}>
            <CustomInput
              className={`${inputClasses}`}
              type="number"
              min={0}
              disabled={disabled}
              name="baseValue"
              value={fixedValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFixedValue(e.target.value);
              }}
              label="Fixed ($):"
            />
          </div>

          {/* Percentage */}
          <div className={`flex flex-${layout} min-w-max`}>
            <CustomInput
              type="number"
              className={`${inputClasses}`}
              value={percentage || ""}
              disabled={disabled}
              min={0}
              name="percentValue"
              onChange={(e) => {
                setPercentage(e.target.value);
              }}
              label="Percentage (%):"
            />
          </div>

          {/* Max Fee */}
          <div className={`flex flex-${layout} min-w-max`}>
            <CustomInput
              type="number"
              className={`${inputClasses}`}
              name="maxFee"
              value={maxFee || ""}
              disabled={disabled}
              min={0}
              onChange={(e) => {
                setMaxFee(e.target.value);
              }}
              label="Max Fee ($):"
            />
          </div>

          {type === "override" && (
            <>
              <CustomSelect
                name="limitType"
                value={(limitType as string) === "" ? "none" : limitType}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                  const selectedValue =
                    e.target.value === "none" ? "" : e.target.value;
                  setLimitType(selectedValue as LimitType);
                }}
                options={limitTypeOptions}
                label="Limit Type:"
                disabled={disabled}
                bodyClassName={`${inputClasses}`}
                className={`flex flex-${layout} min-w-max`}
              />

              <div className={`flex flex-${layout} min-w-max`}>
                <CustomInput
                  type="number"
                  className={`${inputClasses} w-24`}
                  min={0}
                  name="limit"
                  value={limit || ""}
                  disabled={limitType === undefined || disabled}
                  required={limitType !== undefined}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLimit(e.target.value as any);
                  }}
                  label="Limit Value:"
                />
              </div>
            </>
          )}

          <PermissionsProvider
            permission={AfriexPermissions.FEES_PAGE}
            action={AfriexActions.CLICK_FEES_EDIT_DELETE}
          >
            {type === "override" && (
              <div className="flex flex-row items-end justify-between gap-3 mr-4">
                <a
                  className="cursor-pointer border-2 p-1 border-gray-300 rounded-md"
                  onClick={() => onEdit?.(fee)}
                >
                  <PencilIcon className="w-5 h-5 text-successful-500" />
                </a>

                <a
                  className="cursor-pointer border-2 p-1 border-gray-300 rounded-md"
                  onClick={() => fee && onDelete?.(fee)}
                >
                  <XIcon className="w-5 h-5 text-red-500" />
                </a>
              </div>
            )}
          </PermissionsProvider>
        </div>
      </div>
    </div>
  );
};

export default FeeEntry;
