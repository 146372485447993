import { ReceiptConfig } from "./config";
import afriexWhiteLogo from "../../assets/svg/afriex-new-logo-white.png";
import checkboxPng from "../../assets/svg/receipt-checkbox.png";

export const ReceiptFooter = ({
  receiptConfig,
}: {
  receiptConfig: ReceiptConfig;
}) => {
  return (
    <>
      {/* Refund Policy */}
      <div className="m-7">
        <div>
          <p className="text-cyan-600 font-medium text-xs mb-3">
            {receiptConfig.refundPolicyTitle}
          </p>
          <hr className="border-receipt-line" />

          <p
            className="text-xs font-medium text-receipt-policy mt-4 leading-relaxed my-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: receiptConfig.refundPolicyContent,
            }}
          />

          {/* receipt checkboxed display */}
          <div className="receipt-checkbox">
            <img src={checkboxPng} alt="checkbox-png" />
            <span className="text-xs text-gray-700 font-medium">
              {receiptConfig.userRefundPolicy}
            </span>
          </div>
        </div>
      </div>

      <footer className="w-full bg-cyan-600 p-7 h-40 rounded-b-md">
        <div className="logoCon items-center flex gap-1 w-50 mb-3">
          <div id="logoPng" className="logoSvg">
            <img src={afriexWhiteLogo} alt="Afriex Logo" />
          </div>
        </div>
        <hr className="border-white" />
      </footer>
    </>
  );
};

export default ReceiptFooter;
