import { chunk } from "lodash";
import { SupportedAssets } from "../../../types";
import { useMemo } from "react";
import { format } from "date-fns";
import { AfriexPerson } from "../../../types";
import {
  euroCountries,
  mapCountryCodeToFlag,
} from "../../../constants/countries";
import { currencyToCountry } from "../../../constants/countries";
import currencies from "../../../constants/currencies";
import afriexLogo from "../../../assets/svg/afriex-new-logo.png";

const StatementHeader = ({
  balances,
  dates,
  user,
}: {
  user: AfriexPerson;
  balances: Partial<Record<SupportedAssets, number>>;
  dates: Date[];
}) => {
  const startDate = format(dates?.[0], "MMMM d, yyyy");
  const endDate = format(dates?.[1], "MMMM d, yyyy");
  const currentCountryCode = user.currentCountry?.toLocaleUpperCase() || "US"; // Default to US if not available
  const currentCurrency = euroCountries.includes(currentCountryCode)
    ? "EUR"
    : ((Object.keys(currencyToCountry).find(
        (key) => currencyToCountry[key] === currentCountryCode
      ) || "USD") as SupportedAssets);
  const flag = mapCountryCodeToFlag(currentCountryCode);
  const currentBalance = balances[currentCurrency];
  const otherBalances = Object.entries(balances).filter(
    ([currency]) => currency !== currentCurrency
  );

  // Create a map of currency codes to their full names
  const currencyNameMap = useMemo(() => {
    return currencies.reduce(
      (acc, curr) => {
        acc[curr.code] = curr.name;
        return acc;
      },
      {} as Record<string, string>
    );
  }, []);

  return (
    <div className="statement-header">
      <div className="flex justify-between items-center mb-3">
        <div className="logoCon items-center flex gap-1 w-50 mb-3">
          <div id="logoPng" className="logoSvg">
            <img src={afriexLogo} alt="Afriex Logo" />
          </div>
        </div>

        <div className="text-right">
          <p className="text-sm font-semibold">Account Statement</p>
          <p className="text-xs text-cyan-700">
            {startDate} - {endDate}
          </p>
        </div>
      </div>

      {/* Account Holder */}
      <div>
        <p className="text-sm">Account Holder</p>
        <p className="text-sm font-semibold">{user.name?.fullName}</p>
      </div>

      {/* Wallet balances */}
      <div className="flex flex-col mt-5">
        {/* top balance section */}
        <div className="flex items-center bg-receipt-bg rounded-md mb-3">
          <div className="flex items-center gap-2 border-r-2 py-3 pl-4 w-1/3">
            <div className="flex items-center justify-center w-8 h-8 rounded-full overflow-hidden">
              <span className="text-2xl">{flag}</span>
            </div>

            <div>
              <p className="text-xs">{currentCurrency} balance</p>
              <p className="text-xs font-medium">
                {currentBalance?.toLocaleString()} {currentCurrency}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 border-r-2 py-3 px-4 w-1/3">
            <div>
              <p className="text-xs">Money in</p>
              <p className="text-xs font-medium">
                {user?.stats?.transactions?.DEPOSIT?.volumeAllTime?.toLocaleString() ??
                  "0"}{" "}
                {currentCurrency}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-2 py-3 px-4 w-1/3">
            <div>
              <p className="text-xs">Money out</p>
              <p className="text-xs font-medium">
                {" "}
                {user?.stats?.transactions?.WITHDRAW?.volumeAllTime?.toLocaleString() ??
                  "0"}{" "}
                {currentCurrency}
              </p>
            </div>
          </div>
        </div>

        {/* bottom balance section */}
        {otherBalances.some(([_, amount]) => amount > 0) && (
          <div
            className={`flex flex-col items-center bg-receipt-bg rounded-md ${otherBalances?.length > 1 ? "" : "w-1/3"}`}
          >
            {chunk(
              otherBalances.filter(([_, amount]) => amount > 0),
              3
            ).map((balanceGroup, groupIndex, groups) => (
              <div
                key={groupIndex}
                className={`flex w-full ${groupIndex !== groups.length - 1 ? "border-b-2" : ""}`}
              >
                {balanceGroup.map(([currency, amount], idx) => {
                  const currencyName = currencyNameMap[currency] || currency;
                  return (
                    <div
                      key={idx}
                      className={`flex items-center gap-2 py-3 px-4 ${
                        balanceGroup.length === 3
                          ? "w-1/3"
                          : balanceGroup.length === 2
                            ? "w-1/2"
                            : "w-full"
                      } ${idx !== balanceGroup.length - 1 ? "border-r-2" : ""}`}
                    >
                      <div className="flex flex-col gap-1">
                        <p className="text-xs">{`${currencyName} balance`}</p>
                        <p className="text-xs font-medium">
                          {amount.toLocaleString()} {currency}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StatementHeader;
