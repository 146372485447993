import { useCallback, useRef } from "react";
import { identity, pickBy } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { AfriexQueryParams } from "../types";

export type queryParamsType = AfriexQueryParams;

const useFilters = <T extends queryParamsType>(
  setFilters: React.Dispatch<React.SetStateAction<T>>
) => {
  const validDateRef = useRef(false);
  const applyFilters = useCallback(
    (filters: T) => {
      const cleanedFilters = pickBy(filters, identity) as Partial<T>;

      // Check if both fromDate and toDate are present and valid
      validDateRef.current =
        cleanedFilters.fromDate && cleanedFilters.toDate
          ? moment(cleanedFilters.toDate).isAfter(
              moment(cleanedFilters.fromDate)
            )
          : false;

      if (validDateRef.current) {
        cleanedFilters.fromDate = moment(cleanedFilters.fromDate)
          .startOf("day")
          .toISOString();

        cleanedFilters.toDate = moment(cleanedFilters.toDate)
          .endOf("day")
          .toISOString();
      } else if (cleanedFilters.fromDate || cleanedFilters.toDate) {
        toast.error("From Date can not be higher than To Date");
        delete cleanedFilters.fromDate;
        delete cleanedFilters.toDate;
      }
      setFilters(cleanedFilters as T);
    },
    [setFilters]
  );

  return { applyFilters };
};

export default useFilters;
