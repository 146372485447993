import { AfriexTransaction, TransactionTypes } from "../../../types";
import { capitalizeWords } from "../../../helpers/dashboard";
import { getFieldsToDisplay } from "../../../services/transaction/transactionFormatter";
import checkboxPng from "../../../assets/svg/receipt-checkbox.png";
import receiptConfig from "../../../components/Receipt/config";
import Receipt from "../../../components/Receipt";

const TransactionReceipt = ({ data }: { data: AfriexTransaction }) => {
  const getReceiptType = () => {
    switch (data.type) {
      case TransactionTypes.WITHDRAW:
        return `${capitalizeWords(data.type)}`;
      case TransactionTypes.DEPOSIT:
        return "Charge";
      case TransactionTypes.TRANSFER:
        return `${capitalizeWords(data.type)} - Fiat`;
      default:
        return `${capitalizeWords(data.type)} Receipt`;
    }
  };

  let keysToSkip: string[] = [];

  const withdrawKeysToSkip = ["Tier Id", "Internal Reference"];
  const depositKeysToSkip = ["Customer Device Id", "Customer Device Type"];
  const transferKeysToSkip = ["Customer Device Id", "Customer Device Type"];

  switch (data.type) {
    case TransactionTypes.WITHDRAW:
      keysToSkip = withdrawKeysToSkip;
      break;
    case TransactionTypes.DEPOSIT:
      keysToSkip = depositKeysToSkip;
      break;
    case TransactionTypes.TRANSFER:
      keysToSkip = transferKeysToSkip;
      break;
    default:
      keysToSkip = [];
      break;
  }

  return (
    <Receipt type="transaction" transactionType={data.type}>
      <div className="main bg-receipt-bg p-5 mt-5">
        <p className="text-cyan-600 font-medium text-sm mb-3">
          {getReceiptType()}
        </p>
        <hr className="border-receipt-line" />

        {/* Transaction Details */}
        <div className="contentParent mt-2.5">
          {getFieldsToDisplay(data)?.map((entry) => {
            const key = Object.keys(entry)[0];
            const value = Object.values(entry)[0];

            const modValue = Array.isArray(value) ? value.join(", ") : value;

            if (keysToSkip.includes(key) || !value || value?.length === 0) {
              return null;
            }

            return (
              <div
                key={key}
                className="content flex w-full justify-between my-6"
              >
                <p className="contentA text-xs font-medium text-gray-600 flex-shrink-0 w-2/5">
                  {key}
                </p>
                <p className="contentB text-xs font-semibold text-right text-gray-700 flex-grow break-words w-full">
                  {modValue}
                </p>
              </div>
            );
          })}

          {/* receipt checkboxed display */}
          <div className="receipt-checkbox flex items-center">
            <img src={checkboxPng} alt="checkbox-png" />
            <span className="text-xs font-medium text-gray-700 ml-2">
              {receiptConfig.userAcceptancePolicy}
            </span>
          </div>
        </div>
      </div>
    </Receipt>
  );
};

export default TransactionReceipt;
