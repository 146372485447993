import { Fragment } from "react";
import { getFieldsToDisplay } from "../../services/transaction/transactionFormatter";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexTransaction,
} from "../../types";
import Badge from "../common/Badge";
import * as formatDate from "../../utils/dateFormatter";
import { capitalizeWords } from "../../helpers/dashboard";
import { statusColors } from "../../types/Transaction";
import { getTransactionAmountandAsset } from "../../constants/formatters";
import { Link } from "react-router-dom";
import { Button } from "../../components";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { PermissionsProvider } from "../common/PermissionsProvider";

type TransactionDetailProp = {
  data: AfriexTransaction;
};

export default function TransactionDetail({
  data,
}: TransactionDetailProp): JSX.Element {
  const { amount, asset } = getTransactionAmountandAsset(data);

  return (
    <main>
      <div className="flex justify-between items-center">
        {/* Amount */}
        <div className="flex items-center">
          <h2 className="font-semibold text-lg mr-3">
            {amount} {asset}
          </h2>
          {data.status && (
            <div>
              <Badge
                label={data.status}
                colorScheme={statusColors[data.status]}
              />
            </div>
          )}
        </div>
        <div className="flex divide-x">
          <span className="text-md text-gray-500 font-medium">
            {formatDate.DDMonYYYY(data.createdAt?.toString())}
          </span>
        </div>
      </div>

      <div className="bg-white mt-10 w-full">
        <TransactionDetailTableHeader data={data} />
        <TransactionDetailTable data={data} />
      </div>
    </main>
  );
}

export function TransactionDetailTable({
  data,
}: {
  data: AfriexTransaction;
}): JSX.Element {
  return (
    <div className="w-full">
      {getFieldsToDisplay(data)?.map((entry, idx) => {
        const key = Object.keys(entry)[0];
        const value = Object.values(entry)[0];

        if (!value || value?.length === 0) {
          return null;
        }

        return (
          <Fragment key={`container-${idx}`}>
            {key === "Transaction IP Address" ? (
              <PermissionsProvider
                permission={AfriexPermissions.TRANSACTIONS_PAGE}
                action={AfriexActions.VIEW_TRANSACTION_IP}
              >
                <div
                  key={idx}
                  className="flex justify-between py-2 border-b last:border-0"
                >
                  <p className="text-slate-500 text-sm">{key}</p>
                  <p className="text-sm font-medium text-gray-700 w-1/2">
                    {value} <IPDetailsLink ipAddress={value} />
                  </p>
                </div>
              </PermissionsProvider>
            ) : (
              <div
                key={idx}
                className="flex justify-between py-2 border-b last:border-0"
              >
                <p className="text-slate-500 text-sm">{key}</p>
                <p className="text-sm font-medium text-gray-700 w-1/2">
                  {value}
                </p>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

export function TransactionDetailTableHeader({ data }: TransactionDetailProp) {
  return (
    <>
      <div className="w-full flex justify-between items-center font-semibold">
        <h2>{capitalizeWords(data.type?.replace(/_/g, " "))} Transaction</h2>
        <div className="flex gap-4 items-center">
          <div className="text-cyan-700 uppercase">
            {data.processor || "N/A"}
          </div>

          <Link
            to={`/transactions/${data.id}/receipt`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              colorScheme="cyan"
              variant="outline"
              leftIcon={<ExternalLinkIcon className="w-4 h-4" />}
              className="bg-indigo-200"
            >
              View Receipt
            </Button>
          </Link>
        </div>
      </div>
      <hr className="border-1 border-gray-200 mt-2 mb-5 w-full" />
    </>
  );
}

export const IPDetailsLink = ({ ipAddress }: { ipAddress: string }) => (
  <a
    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
    target="_blank"
    href={`https://ip-api.com#${ipAddress}`}
    rel="noreferrer"
  >
    Locate
  </a>
);
