import UserProfile from "./UserProfile";
import countries from "../../../constants/countries";
import { Badge, Button, Link } from "../../../components";
import { ReactElement, useState } from "react";
import { toast } from "react-toastify";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexVerificationStatus,
} from "../../../types";
import { kycService } from "../../../services/kycService";
import { UserDetailsReturnProps } from "./types";
import ComplianceChecklistModal from "./RequestDocumentsModal";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import { statusColors } from "../../../types/Transaction";
import UpdateUserInfoModal from "./UpdateUserInfoModal";
import ConfirmationModal from "../../../components/CustomModal/ConfirmationModal";

type UserOverviewProps = Pick<
  UserDetailsReturnProps,
  | "user"
  | "isLoadingUserDetails"
  | "showModal"
  | "toggleModal"
  | "showComplianceModal"
  | "toggleComplianceModal"
>;
const UserOverview = ({
  isLoadingUserDetails,
  user,
  showModal,
  toggleModal,
  showComplianceModal,
  toggleComplianceModal,
}: UserOverviewProps): ReactElement => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const country = user?.currentCountry?.toUpperCase();
  const verifyText = "Mark as verified";
  const unverifyText = "Unverify this user";
  const isVerified = user?.kyc?.status === AfriexVerificationStatus.Success;
  const isKYCBtnDisabled = !user?.kyc?.status;
  const verifyStatus = isVerified
    ? AfriexVerificationStatus.Failed
    : AfriexVerificationStatus.Success;
  const status = user.isDeactivated ? "failed" : "successful";
  const deactivatedLabel = user.isDeactivated ? "Deactivated" : "Active";

  const verifyAction = async () => {
    setLoading(!loading);
    try {
      await kycService.verifyUser(user.id, verifyStatus);
      toast.success(
        `Successfully ${isVerified ? "unverified" : "verified"} user!`
      );
      setShowConfirmModal(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      toast.error(`Failed to ${isVerified ? "unverify" : "verify"} user!`);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div className="px-3 pt-3 mt-8">
      <h3 className="font-semibold text-gray-900 mb-2">
        {"User Profile".toLocaleUpperCase()}
      </h3>
      <hr className="mb-5" />

      {/* Name */}
      <div className="flex gap-2 items-center">
        <h2 className="font-semibold text-2xl mr-5">{user.name?.fullName}</h2>
        <Badge label={deactivatedLabel} colorScheme={statusColors[status]} />
        <PermissionsProvider
          permission={AfriexPermissions.USERS_PAGE}
          action={AfriexActions.CLICK_UPDATE_USER}
        >
          <Button
            className="mt-1"
            disabled={isLoadingUserDetails}
            variant="ghost"
            onClick={toggleModal}
          >
            Update Info
          </Button>
        </PermissionsProvider>
      </div>

      {/* Phone | Country */}
      <div className="w-full my-5 flex divide-x">
        <div className="mr-5">
          <div className="text-slate-500 text-sm">Phone</div>
          <div>{user.currentPhone}</div>
        </div>

        <div className="pl-10">
          <div className="text-slate-500 text-sm">Country</div>
          <div>{`${country} ${countries[country]}`}</div>
        </div>

        <div className="pl-10">
          <div className="text-slate-500 text-sm">KYC verified</div>
          <div>
            <Badge
              label={isVerified ? "Yes" : "No"}
              className="px-3"
              colorScheme={isVerified ? "green" : "red"}
            />

            <PermissionsProvider
              permission={AfriexPermissions.USERS_PAGE}
              action={AfriexActions.CLICK_USER_VERIFY_UNVERIFY}
            >
              <Button
                disabled={isKYCBtnDisabled}
                variant="ghost"
                onClick={() => setShowConfirmModal(true)}
              >
                {isVerified ? unverifyText : verifyText}
              </Button>
            </PermissionsProvider>

            <PermissionsProvider
              permission={AfriexPermissions.USERS_PAGE}
              action={AfriexActions.CLICK_VIEW_USER_KYC_DETAILS}
            >
              <Link
                to={`/kyc/${user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button variant="ghost">View KYC details</Button>
              </Link>
            </PermissionsProvider>

            <PermissionsProvider
              permission={AfriexPermissions.KYC_PAGE}
              action={AfriexActions.CLICK_KYC_REQUEST_DOCS}
            >
              <Button
                disabled={isKYCBtnDisabled}
                variant="ghost"
                onClick={() => toggleComplianceModal()}
              >
                Request KYC Docs
              </Button>
            </PermissionsProvider>
          </div>
        </div>
      </div>

      <UserProfile
        user={user}
        showEmail
        showUsername
        showUserID
        showReferredBy
        showSecurityEnabled
        showDateOpened
        showDeviceId
        showVirtualAccount
        showStripeUser
        showRegistrationIP
      />

      {/* modal for BackID */}
      {showModal && (
        <UpdateUserInfoModal
          isOpen={showModal}
          user={user}
          onClose={toggleModal}
        />
      )}
      {/* modal for Request Documents */}
      {showComplianceModal && (
        <ComplianceChecklistModal
          isOpen={showComplianceModal}
          onClose={toggleComplianceModal}
          user={user}
        />
      )}

      <ConfirmationModal
        isOpen={showConfirmModal}
        label={
          <>
            Are you sure you want to{" "}
            <span
              className={
                !isVerified ? "text-successful-500" : "text-failed-500"
              }
            >
              {isVerified ? "unverify" : "verify"}
            </span>
            <br />
            <span className="font-semibold">{user.name.fullName}</span>
          </>
        }
        cancelAction={() => setShowConfirmModal(false)}
        cancelActionLabel="No"
        confirmAction={verifyAction}
        confirmActionLabel="Yes"
        loadingLabel={isVerified ? "Unverifying..." : "Verifying..."}
        loading={loading}
        title={`${isVerified ? "Unverify" : "Verify"} ${user.name.fullName}`}
      />
    </div>
  );
};

export default UserOverview;
