import {
  Button,
  SpinningLoader,
  CountryPicker,
  DatePicker,
  AmountInput,
  CustomInput,
  FilePicker,
} from "../../../components";
import {
  AfriexAmount,
  AfriexOTCVolume,
  SupportedCountryCodes,
} from "../../../types";
import useOTCVolumeUpdate from "./useOTCVolumeUpdate";
import { OTCVolumeActionType } from "./types";
import { isEmpty } from "lodash";
import { BigNumber } from "bignumber.js";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";
import { useState } from "react";

interface OTCVolumeModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  initialData?: AfriexOTCVolume;
}

const OTCVolumeModal = ({
  isOpen,
  onClose,
  initialData,
}: OTCVolumeModalProps) => {
  const { handleSubmit, otcRate, dispatch, isLoading, handleOTCRatesUpload } =
    useOTCVolumeUpdate(onClose, initialData);

  const { timestamp, country, fromSymbol, toSymbol, fromAmount, toAmount } =
    otcRate;

  const [date, setDate] = useState(timestamp || new Date());

  const updateAction = {
    type: OTCVolumeActionType.UPDATE_OTC_RATE,
    payload: {},
  };

  const updatedRate =
    toAmount && fromAmount
      ? new BigNumber(toAmount?.toString())
          .dividedBy(fromAmount?.toString())
          ?.toNumber()
      : 0.0;
  const isDisabled =
    !fromAmount ||
    !toAmount ||
    !country ||
    !updatedRate ||
    isLoading ||
    !fromSymbol ||
    !toSymbol;
  const updateLabel = isEmpty(initialData)
    ? "Upload OTC Volume +"
    : "Update OTC Volume";

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      height="h-4/5"
      closeOnOutsideClick
      title={updateLabel}
    >
      <div className="px-4 pt-3">
        {isLoading ? (
          <div className="flex w-full h-full justify-center items-center my-5">
            <SpinningLoader />
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(otcRate);
            }}
            className="flex flex-col space-y-5"
          >
            <DatePicker
              label="Date & Time of Transaction"
              selectedDate={
                timestamp ? new Date(timestamp as unknown as string) : date
              }
              setSelectedDate={(date: Date) => {
                setDate(date);
                dispatch({ ...updateAction, payload: { timestamp: date } });
              }}
              placeholderText="Select Date"
              showTime
            />

            <CountryPicker
              label="Country of Trade"
              onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                dispatch({
                  ...updateAction,
                  payload: {
                    country: evt.target.value as SupportedCountryCodes,
                  },
                })
              }
              value={country}
              filter={{ status: "active" }}
            />

            <AmountInput
              label="Amount Sent"
              onChange={(value: AfriexAmount) =>
                dispatch({
                  ...updateAction,
                  payload: {
                    fromAmount: value?.amount ?? fromAmount,
                    fromSymbol: value.currency ?? fromSymbol,
                  },
                })
              }
              value={{ amount: fromAmount, currency: fromSymbol }}
            />

            <AmountInput
              label="Amount Received"
              onChange={(value: AfriexAmount) =>
                dispatch({
                  ...updateAction,
                  payload: {
                    toAmount: value?.amount ?? toAmount,
                    toSymbol: value.currency ?? toSymbol,
                    rate: updatedRate,
                  },
                })
              }
              value={{ amount: toAmount, currency: toSymbol }}
            />

            <CustomInput
              label="Rate"
              type="number"
              disabled
              value={updatedRate?.toString()}
              className="w-full"
            />

            <FilePicker
              isLoading={false}
              label={
                <>
                  OTC Rates file upload <span className="text-red-500">*</span>
                </>
              }
              name="otcRatesUpload"
              accept="text/csv"
              onChange={handleOTCRatesUpload}
              className="mt-10"
            />

            <div className="flex w-full justify-end space-x-2 py-5">
              <Button onClick={onClose} variant="outline" colorScheme="cyan">
                Cancel
              </Button>

              <Button
                disabled={isDisabled}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                Submit Rates
              </Button>
            </div>
          </form>
        )}
      </div>
    </CustomModal>
  );
};

export default OTCVolumeModal;
