import { subMonths, startOfMonth } from "date-fns";
import { Button, DatePicker, Header } from "../../../components";
import { useParams } from "react-router-dom";
import Spinner from "../../../assets/svg/Spinner";
import { useCallback, useEffect, useState } from "react";
import useUserDetails from "../Details/useUserDetails";

import {
  AfriexActions,
  AfriexPermissions,
  AfriexTransaction,
} from "../../../types";

import useUserTransactionList from "../../Transaction/List/useUserTransactionList";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import moment from "moment";
import GenerateMultiPageButton from "../../../components/Receipt/GenerateMultiPageButton";
import AccountStatement from "./statement";
import { transactionService } from "../../../services/transactionService";

import "./index.css";
import { attachAdminUser } from "../../../helpers/dashboard";
import { toast } from "react-toastify";

const AccountStatementPage: React.FC = () => {
  const params = useParams();
  const userId = params?.userId ?? "";

  const {
    isLoadingWalletBalances,
    isLoadingUserDetails,
    user,
    walletBalances,
  } = useUserDetails(userId);

  const {
    transactionList,
    isLoading,
    filtersToApply,
    selectAppliedFilters,
    handleApplyFilters,
  } = useUserTransactionList(userId);

  const [data, setData] = useState<AfriexTransaction[]>([]);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState<boolean>(true);

  const getInitialStatement = useCallback(async () => {
    let transactionData: AfriexTransaction[] = transactionList;
    if (!isFiltered) {
      const { data } = await transactionService.getUserTransactionList(userId, {
        limit: 100,
        fromDate: moment().startOf("day").toISOString(),
        toDate: moment().endOf("day").toISOString(),
      });

      const filtered = await Promise.all(
        data &&
          data.map(async (transaction) => {
            const at = await attachAdminUser(transaction);
            return {
              ...transaction,
              type: at.type,
              adminUser: at.adminUser,
            };
          })
      );

      transactionData = filtered;
    }
    setData(transactionData);
    setFetchingData(false);
  }, [userId, transactionList, isFiltered]);

  useEffect(() => {
    getInitialStatement();
  }, [getInitialStatement]);

  const fetchStatement = () => {
    setFetchingData(true);
    setIsFiltered(true);
    filtersToApply.limit = 100;
    handleApplyFilters();
  };

  const fromDate = filtersToApply.fromDate
    ? new Date(String(filtersToApply.fromDate))
    : new Date();
  const toDate = filtersToApply.toDate
    ? new Date(String(filtersToApply.toDate))
    : new Date();
  const minDate = startOfMonth(subMonths(new Date(), 6));
  const isDateValid =
    fromDate && toDate && moment(toDate).isAfter(moment(fromDate));

  return (
    <div className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Account Statement" />
      <div className="flex justify-center items-center flex-col mt-8">
        {isLoadingUserDetails || isLoadingWalletBalances ? (
          <Spinner size={8} className="text-cyan-600" />
        ) : (
          <div className="flex flex-col gap-3">
            {/* Filter Transactions By Date here... */}
            <div className="flex justify-between items-center w-680">
              <div className="flex gap-2 items-center">
                <DatePicker
                  label="From Date"
                  selectedDate={fromDate}
                  setSelectedDate={(date) => {
                    selectAppliedFilters("fromDate", date);
                    if (!filtersToApply.toDate) {
                      selectAppliedFilters("toDate", toDate);
                    }
                  }}
                  minDate={minDate}
                  maxDate={new Date()}
                  placeholderText="Select From Date"
                />

                <DatePicker
                  label="To Date"
                  selectedDate={toDate}
                  setSelectedDate={(date) => {
                    selectAppliedFilters("toDate", date);
                    if (!filtersToApply.fromDate) {
                      selectAppliedFilters("fromDate", fromDate);
                    }
                  }}
                  minDate={minDate}
                  maxDate={new Date()}
                  placeholderText="Select To Date"
                />

                <Button
                  onClick={
                    isDateValid
                      ? fetchStatement
                      : () =>
                          toast.error(
                            "From Date can not be higher than To Date"
                          )
                  }
                  colorScheme="cyan"
                  variant="outline"
                  className="self-end"
                >
                  Search
                </Button>
              </div>
              <PermissionsProvider
                permission={AfriexPermissions.TRANSACTIONS_PAGE}
                action={AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT}
              >
                <div className="flex items-end">
                  <GenerateMultiPageButton
                    fileName="statement"
                    label="Download"
                  />
                </div>
              </PermissionsProvider>
            </div>

            {fetchingData ? (
              <div className="w-full flex justify-center pt-5 sm:pt-10">
                <Spinner size={8} className="text-cyan-600" />
              </div>
            ) : data ? (
              <AccountStatement
                user={user}
                balances={walletBalances}
                transactions={data}
                dates={[fromDate, toDate]}
                loading={isLoading || fetchingData}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountStatementPage;
