import { Link } from "react-router-dom";
import SpiningLoader from "../../../components/common/SpinningLoader";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexRateInfo,
  AfriexTier,
} from "../../../types";
import { formatName } from "../../../utils/formatName";
import { toTitleCase } from "../../../utils/services/toTitleCase";
import TierUpdateButton from "./TierUpdateButton";
import { PermissionsProvider } from "../../../components/common/PermissionsProvider";
import * as formatDate from "../../../utils/dateFormatter";
import { formatMonetaryValue } from "../../../helpers/dashboard";

type TierSummaryProp = {
  item: AfriexTier;
  rateInfo?: AfriexRateInfo;
  isLoading?: boolean;
  isDetail?: boolean;
};

const TierSummary = ({
  item,
  isDetail,
  isLoading,
  rateInfo,
}: TierSummaryProp) => {
  return isLoading ? (
    <SpiningLoader />
  ) : (
    <div className="bg-white rounded-lg w-full px-5 sm:px-5 py-5 sm:py-5">
      <div className="flex items-center gap-4 mb-3">
        <h2 className="font-semibold text-xl mr-3">{item.name}</h2>
        {!isDetail ? (
          <PermissionsProvider
            permission={AfriexPermissions.RATES_PAGE}
            action={AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER}
          >
            <Link
              to={`/rates/tiers/${item.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-cyan-700 mr-2 hover:underline"
            >
              View Details
            </Link>
          </PermissionsProvider>
        ) : null}
      </div>

      <div>
        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Tier ID</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">{item?.id}</p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Discount</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatMonetaryValue((item?.discount ?? 0) * 100)}%
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Maximum Allowed Transactions</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.transactionCountThreshold}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Promo Band</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {toTitleCase(item?.code?.replace("_", " ") ?? "")}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Countries Allowed</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item.countries?.join(", ") ?? "ALL"}
          </p>
        </div>

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Tier Status</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {item?.isActive ? (
              <span className="text-green-500">Active</span>
            ) : (
              <span className="text-red-500">Inactive</span>
            )}
          </p>
        </div>

        {item.admin && (
          <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
            <p className="text-slate-500 text-sm">Approved By</p>
            <p className="text-sm font-medium text-gray-700 w-1/2">
              {formatName(item.admin)}
            </p>
          </div>
        )}

        <div className="mb-2 py-2 px-3 flex items-center gap-3 bg-gray-100 justify-between">
          <p className="text-slate-500 text-sm">Date Created</p>
          <p className="text-sm font-medium text-gray-700 w-1/2">
            {formatDate.DDMonYYYY(item?.createdAt?.toString() ?? "")}
          </p>
        </div>
      </div>

      <div className="text-sm text-slate-500 w-full pt-5 sm:pt-5">
        <div className="flex justify-end gap-4">
          <PermissionsProvider
            permission={AfriexPermissions.RATES_PAGE}
            action={AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER}
          >
            <TierUpdateButton initialData={item} rateInfo={rateInfo} />
          </PermissionsProvider>
        </div>
      </div>
    </div>
  );
};

export default TierSummary;
