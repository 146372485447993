import {
  Button,
  SpinningLoader,
  CountryPicker,
  ImageInput,
  CheckBox,
  CustomInput,
  CustomSelect,
  DatePicker,
} from "../../../components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AfriexReward,
  AfriexRewardTier,
  AfriexRewardType,
  SupportedCountryCodes,
} from "../../../types";
import { generateRandomId } from "../../../utils/generateRandomId";
import { mapCountryCodeToFlag } from "../../../constants/countries";
import { isEmpty } from "lodash";
import { UserModalProps } from "../../../components/DeactivateAccount/types";
import CustomModal from "../../../components/CustomModal";
import { CustomTextArea } from "../../../components/common/Input";
import {
  getRewardTierOptions,
  getRewardTypeOptions,
} from "../../../constants/formatters";

interface UpdateRewardModalProps
  extends Pick<UserModalProps, "isOpen" | "onClose"> {
  data?: AfriexReward;
  handleSave: (body: Partial<AfriexReward>) => Promise<void>;
}

const UpdateRewardModal = ({
  isOpen,
  onClose,
  handleSave,
  data,
}: UpdateRewardModalProps) => {
  const [formValues, setFormValues] = useState<AfriexReward>({
    isDeactivated: true,
  } as AfriexReward);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImageAdded, setIsImageAdded] = useState<boolean>(false);
  const imageId = useMemo(() => generateRandomId(), []);
  const defaultFileName = "reward_card_image_" + imageId;
  const [fileName, setFileName] = useState<string>("");
  const [supportedCountries, setSupportedCountries] = useState<
    SupportedCountryCodes[]
  >([]);

  const expiryDate = formValues?.ttl ? new Date(formValues.ttl) : undefined;

  const addCountryToList = (countryCode: SupportedCountryCodes) => {
    if (!supportedCountries.includes(countryCode)) {
      setSupportedCountries([...supportedCountries, countryCode]);
    }
  };

  const removeFromCountryList = (countryCode: SupportedCountryCodes) => {
    if (supportedCountries.includes(countryCode)) {
      setSupportedCountries(
        supportedCountries.filter((x) => x !== countryCode)
      );
    }
  };

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    const body = {
      ...formValues,
      type: formValues?.type || AfriexRewardType["Gift Card"],
      tier: formValues?.tier || AfriexRewardTier.Member,
      supportedCountries,
      imageLink: isImageAdded ? fileName : (formValues?.imageLink ?? ""),
      pointsValue:
        Number(formValues?.pointsValue) ?? formValues?.pointsValue ?? 0,
      isDeactivated: formValues?.isDeactivated ?? false,
    };
    await handleSave(body);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedCountries, formValues, handleSave, fileName]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormValues(data);
      setSupportedCountries(data?.supportedCountries ?? []);
    }
    setFileName(data?.imageLink ?? defaultFileName);
  }, [data, defaultFileName]);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-96"
      height="h-4/5"
      closeOnOutsideClick
      title="Add New Reward"
    >
      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center my-5">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
            <div className="mt-5">
              <CustomInput
                name="name"
                label="Name"
                value={formValues?.name ?? ""}
                className="w-full"
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mt-5">
              <CustomTextArea
                name="description"
                label="Description"
                type="text"
                value={formValues?.description ?? ""}
                className="w-full"
                placeholder="Enter Description"
                onChange={handleInputChange}
                aria-rowspan={4}
                required
              />
            </div>

            <div className="mt-5">
              <CustomSelect
                name="type"
                label="Select Reward Type"
                onChange={handleInputChange}
                value={formValues?.type ?? ""}
                options={getRewardTypeOptions()}
                placeholder="Select Type"
                required
              />
            </div>

            <div className="mt-5">
              <CustomSelect
                name="tier"
                label="Lowest Eligible Tier"
                onChange={handleInputChange}
                value={formValues?.tier?.toLowerCase() ?? ""}
                options={getRewardTierOptions()}
                placeholder="Select Type"
                required
              />
            </div>

            <div className="mt-5">
              <DatePicker
                name="ttl"
                label="Expires On (Optional)"
                selectedDate={expiryDate}
                setSelectedDate={(date) =>
                  setFormValues({
                    ...formValues,
                    ttl: new Date(date)?.toISOString() ?? "",
                  })
                }
                placeholderText="Select Date"
              />
            </div>

            <ImageInput
              label="Upload Image"
              alt="Reward Item Image"
              name={fileName}
              value={formValues?.imageLink ?? ""}
              onChange={(value: string) => {
                setFormValues((x) => ({ ...x, imageLink: value ?? "" }));
                setIsImageAdded(true);
              }}
              required
            />

            <div className="mt-5">
              <CustomInput
                name="pointsValue"
                label="Points Value"
                type="number"
                min="0"
                step="1"
                value={formValues?.pointsValue ?? ""}
                className="w-full"
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mt-5">
              {supportedCountries?.map((countryCode, index) => (
                <div
                  className="flex justify-between space-x-2 border-b-1 py-2"
                  key={index}
                >
                  <label>
                    {countryCode} {mapCountryCodeToFlag(countryCode)}
                  </label>
                  <Button
                    variant={"ghost"}
                    onClick={() => removeFromCountryList(countryCode)}
                  >
                    Remove
                  </Button>
                </div>
              ))}

              <CountryPicker
                label="Add Country Available"
                filter={{ status: "active" }}
                value={
                  supportedCountries?.[supportedCountries?.length - 1] ?? ""
                }
                onChange={(evt: React.ChangeEvent<HTMLSelectElement>) =>
                  addCountryToList(
                    (evt.target.value ||
                      supportedCountries[0]) as SupportedCountryCodes
                  )
                }
              />
            </div>
            <div className="mt-5">
              <CheckBox
                label="Mark as active"
                type="checkbox"
                checked={!formValues?.isDeactivated}
                onChange={(e: any) =>
                  setFormValues((x) => ({
                    ...x,
                    isDeactivated:
                      !e.target?.checked ?? Boolean(formValues?.isDeactivated),
                  }))
                }
              />
            </div>
            <div className="flex w-full justify-end space-x-2 py-5">
              <Button colorScheme="cyan" onClick={onClose} variant="outline">
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
                variant="solid"
                colorScheme="cyan"
              >
                Submit
              </Button>
            </div>
          </form>
        </>
      )}
    </CustomModal>
  );
};

export default UpdateRewardModal;
