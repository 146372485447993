import { ReactNode } from "react";
import { capitalizeWords } from "../../helpers/dashboard";
import { AfriexTransactionStatus } from "../../types";
import { getIcon } from "../../types/Transaction";

type BadgeProps = {
  label?: string | AfriexTransactionStatus;
  colorScheme?: string;
  color?: string;
  background?: string;
  className?: string;
  children?: string | JSX.Element | JSX.Element[] | ReactNode;
};

export default function Badge({
  colorScheme = "gray",
  color,
  background,
  className = "",
  label,
  children,
}: BadgeProps): JSX.Element {
  const bg = background ? background : `${colorScheme}-100`;
  const colour = color ? color : `${colorScheme}-500`;
  const Icon = getIcon(label as AfriexTransactionStatus);
  return (
    <span
      className={`py-1 px-2 inline-flex items-center gap-1 text-sm capitalize font-normal leading-5 rounded-xl bg-${bg} text-${colour} ${className}`}
    >
      {Icon && <Icon className="w-4 h-4" />}
      {capitalizeWords(label?.replaceAll(/_/g, " ") as AfriexTransactionStatus)}
      {children}
    </span>
  );
}
