import { useEffect } from "react";
import { Header } from "../components";
import { useNavigate } from "react-router-dom";
import { appPaths } from "../config/routesConfig";

export default function HomePage(): JSX.Element {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/" + appPaths.users, { replace: true });
  }, [navigate]);

  return (
    <div className="my-5 mx-4 md:mx-10 overflow-x-auto overflow-y-auto max-h-screen">
      <Header title="Home" />
      <div>Welcome to the Admin Portal</div>
    </div>
  );
}
