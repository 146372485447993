import { Form, Formik } from "formik";
import { isEmpty, isFinite, pickBy } from "lodash";
import {
  Button,
  CustomInput,
  CustomModal,
  CustomSelect,
} from "../../components";
import {
  currencyPairOptions,
  FeePaymentMethod,
  limitTypeOptions,
  paymentMethodOptions,
  TransactionFee,
  TransactionTypes,
} from "../../types";
import { logger } from "../../utils/logger";
import { overrideFeeSchema } from "./validation";
import { getFeeTransactionTypesOptions } from "../../constants/formatters";

const FeeEntryModal = ({
  isOpen,
  onClose,
  fee,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (fee?: TransactionFee) => void;
  fee?: Partial<TransactionFee>;
}) => {
  const handleSave = (values: Partial<TransactionFee>) => {
    const {
      paymentMethod,
      baseValue: fixedValue,
      percentValue: percentage,
      maxFeeValue: maxFee,
      currencyPair,
      limitType,
      limit,
    } = values;

    const newOverride: TransactionFee = {
      ...(fee ?? {}),
      paymentMethod: paymentMethod,
      baseValue: isFinite(fixedValue) ? Number(fixedValue) : undefined,
      percentValue: isFinite(percentage) ? Number(percentage) : undefined,
      maxFeeValue: isFinite(maxFee) ? Number(maxFee) : undefined,
      limitType: limitType,
      currencyPair: !isEmpty(currencyPair) ? values.currencyPair : undefined,
      limit: limit,
      type: "override",
      transactionType: values.transactionType ?? TransactionTypes.E2E,
    };

    const cleanOverride = pickBy(
      newOverride,
      (value) => isFinite(value) || !isEmpty(value)
    );
    try {
      // revalidate incase form was modified
      overrideFeeSchema.validateSync(cleanOverride);
    } catch (e: unknown) {
      logger.log((e as Error).message);
      alert(
        "Error validating fee override. Please check the form and try again."
      );
      return;
    }

    onSave(cleanOverride as TransactionFee);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      width="w-2/5"
      closeOnOutsideClick
      title="Fee Entry"
    >
      <Formik
        initialValues={{
          type: "override",
          paymentMethod: "",
          currencyPair: "*:*",
          baseValue: "",
          percentValue: "",
          limitType: "",
          limit: "",
          transactionType: "",
          ...(fee ?? {}),
        }}
        validationSchema={overrideFeeSchema}
        onSubmit={(values) => {
          return handleSave(values as Partial<TransactionFee>);
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange, setFieldValue }) => {
          const methodOrCurrencyError =
            errors.paymentMethod || errors.currencyPair;
          const destination = values.currencyPair?.split(":")?.[1];
          const destinationCurrency =
            destination === "*" ? undefined : destination;
          return (
            <Form className="flex flex-col gap-4 mb-4">
              <div className="flex items-center justify-between gap-3">
                <CustomSelect
                  name="transactionType"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    handleChange(e);
                  }}
                  value={values.transactionType}
                  options={getFeeTransactionTypesOptions()}
                  placeholder="--Transaction Type--"
                  label="Transaction Type:"
                  className="w-1/2"
                />

                <CustomSelect
                  name="paymentMethod"
                  disabled={
                    values.currencyPair !== "*:*" &&
                    values.currencyPair?.length > 3
                  }
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    const selectedMethod =
                      e.target.value === "any"
                        ? ""
                        : Object.create(FeePaymentMethod)[e.target.value];
                    if (selectedMethod) {
                      setFieldValue("currencyPair", undefined);
                    }
                    setFieldValue("paymentMethod", selectedMethod);
                  }}
                  value={
                    values.paymentMethod === "" ? "any" : values.paymentMethod
                  } // Map empty string to "any" for display purposes
                  options={paymentMethodOptions}
                  label="Payment Method:"
                  className="w-1/2"
                />
              </div>

              <div className="flex items-center justify-between gap-3">
                <div className="w-1/2">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Currency Pair:
                    </label>

                    <div className="flex items-center gap-2">
                      <CustomSelect
                        name="sourceCurrency"
                        disabled={!isEmpty(values.paymentMethod)}
                        value={values.currencyPair?.split(":")?.[0] ?? "*"}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const destination =
                            values.currencyPair?.split(":")?.[1];
                          const currencyPair = `${e.target.value}:${isEmpty(destination) ? "*" : destination}`;
                          const pair =
                            currencyPair.replace(/\*/g, "")?.length <= 1
                              ? undefined
                              : currencyPair;
                          handleChange("currencyPair")({
                            ...e,
                            target: {
                              name: "currencyPair",
                              value: pair,
                            },
                          });
                          if (
                            values.paymentMethod !== "" &&
                            e.target.value !== "*"
                          ) {
                            setFieldValue("paymentMethod", undefined);
                          }
                        }}
                        options={currencyPairOptions}
                        className="w-1/2"
                      />

                      <CustomSelect
                        name="destinationCurrency"
                        disabled={!isEmpty(values.paymentMethod)}
                        value={values.currencyPair?.split(":")?.[1] ?? "*"}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          const source = values.currencyPair?.split(":")?.[0];
                          const currencyPair = `${isEmpty(source) ? "*" : source}:${e.target.value}`;
                          const pair =
                            currencyPair.replace(/\*/g, "")?.length <= 1
                              ? undefined
                              : currencyPair;
                          handleChange("currencyPair")({
                            ...e,
                            target: {
                              name: "currencyPair",
                              value: pair,
                            },
                          });
                          if (
                            values.paymentMethod !== "" &&
                            e.target.value !== "*"
                          ) {
                            setFieldValue("paymentMethod", undefined);
                          }
                        }}
                        options={currencyPairOptions}
                        className="w-1/2"
                      />
                    </div>

                    <div className="">
                      <p
                        className={`${methodOrCurrencyError ? "text-red-500" : "text-gray-500"} text-sm`}
                      >
                        {methodOrCurrencyError}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between gap-3 w-1/2">
                  <CustomSelect
                    name="limitType"
                    value={values.limitType === "" ? "none" : values.limitType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const selectedValue =
                        e.target.value === "none" ? "" : e.target.value;
                      setFieldValue("limitType", selectedValue);
                    }}
                    options={limitTypeOptions}
                    label="Limit Type:"
                    className="w-2/3"
                  />

                  <CustomInput
                    type="number"
                    min={0}
                    name="limit"
                    value={values.limit}
                    disabled={isEmpty(values.limitType)}
                    required={!isEmpty(values.limitType)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                    }}
                    error={errors.limit}
                    label="Limit Value ($):"
                    className="w-full"
                  />
                </div>
              </div>

              <p className="text-gray-500 text-sm">
                <span className="font-medium text-gray-600">PS: </span>When
                limits are set, the limit value fee, will be applied only if the
                limit on transaction amount in the USD is met.
              </p>
              <hr className="col-span-2" />

              <div className="flex items-center justify-between">
                <CustomInput
                  type="number"
                  min={0}
                  name="baseValue"
                  value={values.baseValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.baseValue}
                  label={`Fixed (${destinationCurrency ?? "$"}):`}
                />

                <CustomInput
                  type="number"
                  max={5}
                  name="percentValue"
                  value={values.percentValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                  error={errors.baseValue}
                  label="Percentage (%):"
                />
              </div>

              <p className="text-gray-500 text-sm">
                Either fixed amount or percentage be entered or both. <br />
                Formular of Fee:
                <span className="mx-1 text-black">
                  fixed + (amount * percent / 100)
                </span>
                <br />
                <span className="font-medium text-gray-600">NB: </span>Fixed fee
                currency is source currency unless destination currency is
                specified.
              </p>
              <hr className="col-span-2" />

              <div className="flex justify-end">
                <Button colorScheme="cyan" type="submit" className="h-10">
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default FeeEntryModal;
