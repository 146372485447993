import {
  AfriexAccessConfig,
  AfriexActions,
  AfriexPermissions,
  AfriexRoles,
} from "../types";

export const afriexRolesConfig: AfriexAccessConfig = {
  roles: [
    {
      role: AfriexRoles.GROWTH_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
          ],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.GROWTH_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
          ],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [AfriexActions.CLICK_REFERRAL_SEARCH_FILTER],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [AfriexActions.CLICK_SETTINGS_SEARCH_FILTER],
        },
      ],
    },
    {
      role: AfriexRoles.CUSTOMER_SUPPORT_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.VIEW_USER_REWARD_POINTS,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.CUSTOMER_SUPPORT_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.VIEW_USER_REWARD_POINTS,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [AfriexActions.CLICK_REFERRAL_SEARCH_FILTER],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [AfriexActions.CLICK_SETTINGS_SEARCH_FILTER],
        },
      ],
    },
    {
      role: AfriexRoles.OPERATIONS_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_REFUND_TRANSACTION,
            AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT,
            AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.VIEW_USER_REWARD_POINTS,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
            AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [AfriexActions.CLICK_REFERRAL_SEARCH_FILTER],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [
            AfriexActions.CLICK_PROCESSORS_UPDATE,
            AfriexActions.CLICK_PROCESSORS_NOTIFY,
          ],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.OPERATIONS_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_REFUND_TRANSACTION,
            AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT,
            AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.VIEW_USER_REWARD_POINTS,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
            AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [AfriexActions.CLICK_REFERRAL_SEARCH_FILTER],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [AfriexActions.CLICK_SETTINGS_SEARCH_FILTER],
        },
      ],
    },
    {
      role: AfriexRoles.PRODUCT_ENG_QA_DESIGN_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [
            AfriexActions.CLICK_REWARDS_ADD_UPDATE,
            AfriexActions.CLICK_REWARDS_MANAGE_CARD,
            AfriexActions.CLICK_REWARDS_UPLOAD_REMOVE,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.PRODUCT_ENG_QA_DESIGN_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
          ],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [AfriexActions.CLICK_REFERRAL_SEARCH_FILTER],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [
            AfriexActions.CLICK_REWARDS_ADD_UPDATE,
            AfriexActions.CLICK_REWARDS_MANAGE_CARD,
            AfriexActions.CLICK_REWARDS_UPLOAD_REMOVE,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [AfriexActions.CLICK_SETTINGS_SEARCH_FILTER],
        },
      ],
    },
    {
      role: AfriexRoles.COMPLIANCE_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT,
            AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_TRANSACTION_IP,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_UPDATE_USER,
            AfriexActions.CLICK_USER_VERIFY_UNVERIFY,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_USER_REGISTRATION_IP,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [
            AfriexActions.CLICK_KYC_SEARCH_FILTER,
            AfriexActions.CLICK_KYC_REQUEST_DOCS,
            AfriexActions.CLICK_DOWNLOAD_KYC_RECEIPT,
            AfriexActions.CLICK_KYC_UPDATE_INFO,
            AfriexActions.CLICK_KYC_RETRY_VERIFF,
            AfriexActions.CLICK_KYC_DELETE_VERIFF_SESSION,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [AfriexActions.CLICK_UPDATE_DISPUTES],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [
            AfriexActions.CLICK_BLOCKED_ADD_PAYMENT_DESTINATION,
            AfriexActions.CLICK_BLOCKED_SEARCH_FILTER,
            AfriexActions.CLICK_BLOCKED_UNBLOCK_BTN,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_DEACTIVATE_ADMIN,
            AfriexActions.CLICK_SETTINGS_UPDATE_ROLE,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.COMPLIANCE_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT,
            AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_TRANSACTION_IP,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_UPDATE_USER,
            AfriexActions.CLICK_USER_VERIFY_UNVERIFY,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_USER_REGISTRATION_IP,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [
            AfriexActions.CLICK_KYC_SEARCH_FILTER,
            AfriexActions.CLICK_KYC_REQUEST_DOCS,
            AfriexActions.CLICK_DOWNLOAD_KYC_RECEIPT,
            AfriexActions.CLICK_KYC_UPDATE_INFO,
            AfriexActions.CLICK_KYC_RETRY_VERIFF,
            AfriexActions.CLICK_KYC_DELETE_VERIFF_SESSION,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [AfriexActions.CLICK_UPDATE_DISPUTES],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [
            AfriexActions.CLICK_BLOCKED_ADD_PAYMENT_DESTINATION,
            AfriexActions.CLICK_BLOCKED_SEARCH_FILTER,
            AfriexActions.CLICK_BLOCKED_UNBLOCK_BTN,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_DEACTIVATE_ADMIN,
            AfriexActions.CLICK_SETTINGS_UPDATE_ROLE,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.FINANCE_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_REFUND_TRANSACTION,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.CLICK_USER_TIERS_UPDATE_DELETE,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_REFERRAL_PAYOUT,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [
            AfriexActions.CLICK_RATES_UPLOAD,
            AfriexActions.CLICK_RATES_UPLOAD_PRODUCTION,
            AfriexActions.CLICK_RATES_TOLERANCE_INPUT,
            AfriexActions.CLICK_RATES_OTC_UPLOAD,
            AfriexActions.CLICK_RATES_APPROVE,
            AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER,
            AfriexActions.CLICK_RATES_SEARCH_FILTER,
          ],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [
            AfriexActions.CLICK_FEES_UPDATE,
            AfriexActions.CLICK_FEES_ADD_OVERRIDE,
            AfriexActions.CLICK_FEES_EDIT_DELETE,
          ],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [
            AfriexActions.CLICK_PROCESSORS_UPDATE,
            AfriexActions.CLICK_PROCESSORS_NOTIFY,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.FINANCE_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_REFUND_TRANSACTION,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.CLICK_USER_TIERS_UPDATE_DELETE,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_REFERRAL_PAYOUT,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [
            AfriexActions.CLICK_RATES_UPLOAD,
            AfriexActions.CLICK_RATES_UPLOAD_PRODUCTION,
            AfriexActions.CLICK_RATES_TOLERANCE_INPUT,
            AfriexActions.CLICK_RATES_OTC_UPLOAD,
            AfriexActions.CLICK_RATES_APPROVE,
            AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER,
            AfriexActions.CLICK_RATES_SEARCH_FILTER,
          ],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [
            AfriexActions.CLICK_FEES_UPDATE,
            AfriexActions.CLICK_FEES_ADD_OVERRIDE,
            AfriexActions.CLICK_FEES_EDIT_DELETE,
          ],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [
            AfriexActions.CLICK_PROCESSORS_UPDATE,
            AfriexActions.CLICK_PROCESSORS_NOTIFY,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.PEOPLES_OPERATIONS_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.PEOPLES_OPERATIONS_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [AfriexActions.CLICK_SETTINGS_SEARCH_FILTER],
        },
      ],
    },
    {
      role: AfriexRoles.ENGINEERING_MANAGER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_TRANSACTION_EXPORT,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
            AfriexActions.CLICK_REFUND_TRANSACTION,
            AfriexActions.CLICK_DOWNLOAD_TRANSACTION_RECEIPT,
            AfriexActions.CLICK_DOWNLOAD_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_TRANSACTION_IP,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.VIEW_USER_STRIPE_DETAILS,
            AfriexActions.VIEW_USER_REWARD_POINTS,
            AfriexActions.CLICK_UPDATE_USER,
            AfriexActions.CLICK_USER_VERIFY_UNVERIFY,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
            AfriexActions.CLICK_CREDIT_DEBIT_USER,
            AfriexActions.CLICK_SWAP_USER_BALANCE,
            AfriexActions.CLICK_USER_TIERS_UPDATE_DELETE,
            AfriexActions.CLICK_VIEW_USER_KYC_DETAILS,
            AfriexActions.CLICK_DEACTIVATE_USER,
            AfriexActions.VIEW_USER_INTERAC_EMAILS,
            AfriexActions.CLICK_VIEW_ACCOUNT_STATEMENT,
            AfriexActions.VIEW_USER_REGISTRATION_IP,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [
            AfriexActions.CLICK_KYC_SEARCH_FILTER,
            AfriexActions.CLICK_KYC_REQUEST_DOCS,
            AfriexActions.CLICK_DOWNLOAD_KYC_RECEIPT,
            AfriexActions.CLICK_KYC_UPDATE_INFO,
            AfriexActions.CLICK_KYC_RETRY_VERIFF,
            AfriexActions.CLICK_KYC_DELETE_VERIFF_SESSION,
          ],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_REFERRAL_PAYOUT,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Disputes",
          permission: AfriexPermissions.DISPUTES_PAGE,
          actions: [AfriexActions.CLICK_UPDATE_DISPUTES],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [
            AfriexActions.CLICK_RATES_UPLOAD,
            AfriexActions.CLICK_RATES_UPLOAD_PRODUCTION,
            AfriexActions.CLICK_RATES_TOLERANCE_INPUT,
            AfriexActions.CLICK_RATES_OTC_UPLOAD,
            AfriexActions.CLICK_RATES_APPROVE,
            AfriexActions.CLICK_RATES_CREATE_UPDATE_TIER,
            AfriexActions.CLICK_RATES_SEARCH_FILTER,
          ],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [
            AfriexActions.CLICK_FEES_UPDATE,
            AfriexActions.CLICK_FEES_ADD_OVERRIDE,
            AfriexActions.CLICK_FEES_EDIT_DELETE,
          ],
        },
        {
          name: "Bulk Payments",
          permission: AfriexPermissions.BULK_PAYMENTS_PAGE,
          actions: [
            AfriexActions.CLICK_BULK_PAYMENT_UPLOAD,
            AfriexActions.VIEW_RECENT_BULK_PAYMENTS,
          ],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [
            AfriexActions.CLICK_PROCESSORS_UPDATE,
            AfriexActions.CLICK_PROCESSORS_NOTIFY,
          ],
        },
        {
          name: "Blocked",
          permission: AfriexPermissions.BLOCKED_PAGE,
          actions: [
            AfriexActions.CLICK_BLOCKED_ADD_PAYMENT_DESTINATION,
            AfriexActions.CLICK_BLOCKED_SEARCH_FILTER,
            AfriexActions.CLICK_BLOCKED_UNBLOCK_BTN,
          ],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [
            AfriexActions.CLICK_REWARDS_ADD_UPDATE,
            AfriexActions.CLICK_REWARDS_MANAGE_CARD,
            AfriexActions.CLICK_REWARDS_UPLOAD_REMOVE,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_SEARCH_FILTER,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
            AfriexActions.CLICK_SETTINGS_DEACTIVATE_ADMIN,
            AfriexActions.CLICK_SETTINGS_UPDATE_ROLE,
          ],
        },
      ],
    },
    {
      role: AfriexRoles.ENGINEERING_MEMBER,
      pages: [
        {
          name: "Home",
          permission: AfriexPermissions.HOME_PAGE,
          actions: [],
        },
        {
          name: "Transactions",
          permission: AfriexPermissions.TRANSACTIONS_PAGE,
          actions: [
            AfriexActions.CLICK_TRANSACTION_SEARCH,
            AfriexActions.CLICK_VIEW_USER_TRANSACTIONS,
          ],
        },
        {
          name: "Users",
          permission: AfriexPermissions.USERS_PAGE,
          actions: [
            AfriexActions.CLICK_USER_SEARCH,
            AfriexActions.CLICK_USER_SUMMARY_VIEW_MORE,
            AfriexActions.VIEW_USER_PHONE_NUMBER,
            AfriexActions.CLICK_ADD_USER_SECURITY_NOTES,
            AfriexActions.CLICK_USER_BLOCK_UNBLOCK,
          ],
        },
        {
          name: "KYC",
          permission: AfriexPermissions.KYC_PAGE,
          actions: [AfriexActions.CLICK_KYC_SEARCH_FILTER],
        },
        {
          name: "Referrals",
          permission: AfriexPermissions.REFERRAL_PAGE,
          actions: [
            AfriexActions.CLICK_REFERRAL_SEARCH_FILTER,
            AfriexActions.CLICK_ADD_REMOVE_REFERRAL_TIER_USER,
            AfriexActions.CLICK_CREATE_UPDATE_REFERRAL_TIER,
            AfriexActions.CLICK_CREATE_UPDATE_SIGNUP_BONUS_CODE,
          ],
        },
        {
          name: "Notifications",
          permission: AfriexPermissions.PUSH_NOTIFICATIONS_PAGE,
          actions: [],
        },
        {
          name: "Rates",
          permission: AfriexPermissions.RATES_PAGE,
          actions: [],
        },
        {
          name: "Fees",
          permission: AfriexPermissions.FEES_PAGE,
          actions: [],
        },
        {
          name: "Processors",
          permission: AfriexPermissions.PROCESSORS_PAGE,
          actions: [],
        },
        {
          name: "Rewards",
          permission: AfriexPermissions.REWARDS_PAGE,
          actions: [
            AfriexActions.CLICK_REWARDS_ADD_UPDATE,
            AfriexActions.CLICK_REWARDS_MANAGE_CARD,
            AfriexActions.CLICK_REWARDS_UPLOAD_REMOVE,
          ],
        },
        {
          name: "Settings",
          permission: AfriexPermissions.SETTINGS_PAGE,
          actions: [
            AfriexActions.CLICK_SETTINGS_UPDATE_REFERRAL,
            AfriexActions.CLICK_SETTINGS_MANAGE_COUNTRY,
            AfriexActions.CLICK_SETTINGS_ADD_CURRENCY,
            AfriexActions.CLICK_SETTINGS_MANAGE_CURRENCY,
          ],
        },
      ],
    },
  ],
};

/**
 * Retrieves roles that have a specific permission.
 * @param permission - The permission to filter roles by.
 * @returns An array of AfriexRoles that have the specified permission.
 */
export function getRolesWithPermission(
  permission: AfriexPermissions
): AfriexRoles[] {
  const rolesWithPermission = afriexRolesConfig.roles.filter((roleConfig) =>
    roleConfig.pages.some((page) => page.permission === permission)
  );

  return rolesWithPermission.map((roleConfig) => roleConfig.role);
}

/**
 * Retrieves all defined roles.
 * @returns An array of all AfriexRoles.
 */
export function getAllRoles(): AfriexRoles[] {
  return afriexRolesConfig.roles.map((roleConfig) => roleConfig.role);
}

/**
 * Checks if the current role has the specified permission and action.
 * @param permission - The permission to check.
 * @param action - The action to check.
 * @returns True if the role has the permission and action, false otherwise.
 */
export function hasPermission(
  permission: AfriexPermissions,
  action: AfriexActions
): boolean {
  const role = localStorage.getItem("admin-role") as AfriexRoles;

  // Validate role existence
  if (!role || !Object.values(AfriexRoles).includes(role)) {
    return false;
  }

  // Find the role's permissions
  const roleConfig = afriexRolesConfig.roles.find((rc) => rc.role === role);

  if (!roleConfig) {
    return false;
  }

  // Check for the specific permission and action
  return roleConfig.pages.some(
    (page) => page.permission === permission && page.actions.includes(action)
  );
}
