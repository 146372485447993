import { useParams } from "react-router-dom";
import { Header, TableSkeleton } from "../../../components";
import useRewardsTransactions from "./useRewardsTransactions";
import RewardTransactionTable from "./RewardTransactionTable";
import useUserDetails from "../../User/Details/useUserDetails";

const RewardTransactions = () => {
  const { userId } = useParams();
  const { loading, transactions } = useRewardsTransactions(userId ?? "");
  const { isLoadingUserDetails, user: userDetails } = useUserDetails(
    userId ?? ""
  );
  return (
    <main className="mt-10 mb-5 mx-4 md:mx-10">
      <Header title="Reward Transactions" />

      <section className="flex pt-5 md:pt-5 justify-center">
        {loading || isLoadingUserDetails ? (
          <TableSkeleton />
        ) : (
          <RewardTransactionTable items={transactions} user={userDetails} />
        )}
      </section>
    </main>
  );
};

export default RewardTransactions;
