import {
  ClockIcon,
  XCircleIcon,
  RefreshIcon,
  CheckIcon,
  ReceiptRefundIcon,
  BadgeCheckIcon,
  DocumentSearchIcon,
} from "@heroicons/react/outline";
import { SVGProps } from "react";

export enum TransactionTypes {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  TRANSFER = "TRANSFER",
  SWAP = "SWAP",
  REVERSAL = "REVERSAL",
  REFERRAL = "REFERRAL",
  ACH = "ACH",
  WELCOME_BONUS = "WELCOME_BONUS",
  E2E = "E2E",
  SCHEDULED = "SCHEDULED",
  REWARD = "REWARD",
  VIRTUAL_CARD_LOAD = "VIRTUAL_CARD_LOAD",
  VIRTUAL_CARD_UNLOAD = "VIRTUAL_CARD_UNLOAD",
  VIRTUAL_CARD_SPEND = "VIRTUAL_CARD_SPEND",
}

export enum TransactionChannels {
  WIDGET = "WIDGET",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  MOBILE_MONEY = "MOBILE_MONEY",
  CARD = "CARD",
  CRYPTO = "CRYPTO",
  INTERNAL = "INTERNAL",
  ADMIN = "ADMIN",
  WALLET = "WALLET",
  VIRTUAL_BANK_ACCOUNT = "VIRTUAL_BANK_ACCOUNT",
  ACH_BANK_ACCOUNT = "ACH_BANK_ACCOUNT",
}

export enum AdminTransactionTypes {
  "ADMIN_DEPOSIT" = TransactionTypes.DEPOSIT,
  "ADMIN_WITHDRAW" = TransactionTypes.WITHDRAW,
  "ADMIN_WELCOME_BONUS" = TransactionTypes.WELCOME_BONUS,
}

export enum FeeTransactionTypes {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  SWAP = "SWAP",
  E2E = "E2E",
  VIRTUAL_CARD_LOAD = "VIRTUAL_CARD_LOAD",
  VIRTUAL_CARD_UNLOAD = "VIRTUAL_CARD_UNLOAD",
  VIRTUAL_CARD_SPEND = "VIRTUAL_CARD_SPEND",
  ACH = "ACH",
}

export enum AfriexTransactionStatus {
  CANCELLED = "CANCELLED",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  REFUNDED = "REFUNDED",
  RETRY = "RETRY",
  UNKNOWN = "UNKNOWN",
  REJECTED = "REJECTED",
  IN_REVIEW = "IN_REVIEW",
  DISPUTED = "DISPUTED",
  DISPUTE_RESOLVED = "DISPUTE_RESOLVED",
  DISPUTE_WON = "DISPUTE_WON",
  DISPUTE_LOST = "DISPUTE_LOST",
  DISPUTE_EVIDENCE_SUBMITTED = "DISPUTE_EVIDENCE_SUBMITTED",
}

export enum DisplayAfriexTransactionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  PROCESSING = "PROCESSING",
  REFUNDED = "REFUNDED",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  DISPUTED = "DISPUTED",
  DISPUTE_RESOLVED = "DISPUTE_RESOLVED",
  DISPUTE_WON = "DISPUTE_WON",
  DISPUTE_LOST = "DISPUTE_LOST",
  DISPUTE_EVIDENCE_SUBMITTED = "DISPUTE_EVIDENCE_SUBMITTED",
}

export interface IBankAccountInfo {
  account_number: string;
  bank_name: string;
  account_name: string;
  phoneNumber: string;
  bank_code: string;
}

export interface ICustomerDetails {
  name: string;
  email: string;
  username: string;
  photo: string;
  phone: string;
}

export interface AfriexAgentDetails {
  userName: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  country: string;
}

export interface AfriexTransactionUpdate {
  state: AfriexTransactionStatus;
}

export interface LedgerRequestParams {
  currency: string;
  amount: number;
  userId: string;
  action: "credit" | "debit";
  reason: LedgerReasons;
}

export enum LedgerReasons {
  MISSING_DEPOSIT = "MISSING_DEPOSIT",
  REFERRAL_BONUS = "REFERRAL_BONUS",
  LOST_DISPUTE = "LOST_DISPUTE",
  STRIPE_REFUND = "STRIPE_REFUND",
  WRONG_CREDIT = "WRONG_CREDIT",
  OTHERS = "OTHERS",
}

export const statusIcons: Partial<{
  [key in AfriexTransactionStatus]: React.FC<SVGProps<SVGSVGElement>>;
}> = {
  [AfriexTransactionStatus.PENDING]: ClockIcon,
  [AfriexTransactionStatus.CANCELLED]: XCircleIcon,
  [AfriexTransactionStatus.SUCCESS]: CheckIcon,
  [AfriexTransactionStatus.PROCESSING]: RefreshIcon,
  [AfriexTransactionStatus.FAILED]: XCircleIcon,
  [AfriexTransactionStatus.REJECTED]: XCircleIcon,
  [AfriexTransactionStatus.REFUNDED]: ReceiptRefundIcon,
  [AfriexTransactionStatus.IN_REVIEW]: DocumentSearchIcon,
  [AfriexTransactionStatus.DISPUTED]: DocumentSearchIcon,
  [AfriexTransactionStatus.DISPUTE_RESOLVED]: BadgeCheckIcon,
  [AfriexTransactionStatus.DISPUTE_WON]: BadgeCheckIcon,
  [AfriexTransactionStatus.DISPUTE_LOST]: XCircleIcon,
  [AfriexTransactionStatus.DISPUTE_EVIDENCE_SUBMITTED]: DocumentSearchIcon,
};

// Function to get icon
export const getIcon = (
  label: AfriexTransactionStatus
): React.FC<SVGProps<SVGSVGElement>> | undefined => {
  return statusIcons[label];
};

// These are custom colors defined in tailwind.config.js
export const statusColors: { [key: string | AfriexTransactionStatus]: string } =
  {
    pending: "pending",
    successful: "successful",
    failed: "failed",
    cancelled: "failed",
    completed: "successful",
    rejected: "failed",
    in_review: "refunded",
    disputed: "indigo",
    dispute_resolved: "successful",
    dispute_won: "successful",
    dispute_lost: "failed",
    dispute_evidence_submitted: "blue",
    [AfriexTransactionStatus.PENDING]: "pending",
    [AfriexTransactionStatus.SUCCESS]: "successful",
    [AfriexTransactionStatus.FAILED]: "failed",
    [AfriexTransactionStatus.CANCELLED]: "failed",
    [AfriexTransactionStatus.REFUNDED]: "refunded",
    [AfriexTransactionStatus.IN_REVIEW]: "refunded",
    [AfriexTransactionStatus.DISPUTED]: "indigo",
    [AfriexTransactionStatus.DISPUTE_RESOLVED]: "successful",
    [AfriexTransactionStatus.DISPUTE_WON]: "successful",
    [AfriexTransactionStatus.DISPUTE_LOST]: "failed",
    [AfriexTransactionStatus.DISPUTE_EVIDENCE_SUBMITTED]: "blue",
  };
