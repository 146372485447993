import { forwardRef, ReactNode, useEffect, useRef, useState } from "react";

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  [rest: string]: any;
}

export interface CInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  error?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

export interface TextAreaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
}

export interface SelectProps {
  label?: string;
  placeholder?: string;
  className?: string;
  options: { key: string; value: string }[];
  bodyClassName?: string;
  [key: string]: any;
}

export const CustomInput = forwardRef<HTMLInputElement, CInputProps>(
  (
    { type = "text", label, error, className, leftIcon, rightIcon, ...props },
    ref
  ) => {
    return (
      <div className={className?.includes("isFull") ? "w-full" : ""}>
        {label && (
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        )}
        <div className="relative">
          {leftIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {leftIcon}
            </div>
          )}
          <input
            ref={ref}
            {...props}
            type={type}
            className={`block ${
              leftIcon ? "pl-10" : "pl-3"
            } sm:text-sm border-gray-300 rounded-md focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              error ? "border-red-500" : ""
            } ${className}`}
          />
          {rightIcon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none z-20">
              {rightIcon}
            </div>
          )}
        </div>
        {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
      </div>
    );
  }
);
CustomInput.displayName = "CustomInput";

export const Select: React.FC<SelectProps> = ({
  options,
  label,
  placeholder,
  value,
  className,
  bodyClassName,
  ...props
}) => {
  return (
    <div className={className}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <select
        {...props}
        value={value || ""}
        className={`block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md cursor-pointer ${bodyClassName}`}
      >
        <option value="" disabled>
          {placeholder}
        </option>

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.key}
          </option>
        ))}
      </select>
    </div>
  );
};

export function CheckBox({
  label,
  className,
  checked,
  ...rest
}: InputProps): JSX.Element {
  const checkedStateClassNames =
    "w-4 h-4 text-blue-600 bg-green-500 border-green-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
  const uncheckedStateClassNames =
    "w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600";
  const classNames = checked
    ? checkedStateClassNames
    : uncheckedStateClassNames;
  return (
    <div className="flex items-center mb-4">
      <input
        type="checkbox"
        className={classNames}
        checked={checked}
        {...rest}
      />
      <label
        htmlFor="checkbox"
        className={`ml-2 ${className?.includes("xs") ? "text-xs" : "text-sm"} font-medium text-gray-900 dark:text-gray-300`}
      >
        {label}
      </label>
    </div>
  );
}

export const CustomTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ label, error, className, ...props }, ref) => {
    const [value, setValue] = useState("");
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = evt.target.value;
      setValue(val);
      if (props.onChange) {
        props.onChange(evt);
      }
    };

    return (
      <div className={className?.includes("isFull") ? "w-full" : ""}>
        {label && (
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        )}
        <div className="relative">
          <textarea
            ref={ref ? ref : textAreaRef}
            {...props}
            value={value}
            onChange={handleChange}
            className={`block w-full "pl-3" sm:text-sm border-gray-300 rounded-md focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${
              error ? "border-red-500" : ""
            } ${className}`}
            rows={props["aria-rowspan"]}
          />
        </div>
        {error && <p className="mt-2 text-sm text-red-400">{error}</p>}
      </div>
    );
  }
);
CustomTextArea.displayName = "CustomTextArea";

// ---- GRADUALLY PHASE THIS OUT FOR THE MORE ROBUST `CustomInput` ABOVE ----
export function Input({ label, className, ...rest }: InputProps): JSX.Element {
  return (
    <label className="block">
      <span className="text-gray-700">{label}</span>
      <input
        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${className}`}
        {...rest}
      />
    </label>
  );
}

// ---- GRADUALLY PHASE THIS OUT FOR THE MORE ROBUST `CustomTextArea` ABOVE ----
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const { scrollHeight } = textAreaRef;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};
export function TextArea({
  label,
  className,
  onChange,
  ...rest
}: InputProps): JSX.Element {
  const [value, setValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, value);

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value;
    setValue(val);
    onChange(evt);
  };
  return (
    <label className="block">
      <span className="text-gray-700">{label}</span>
      <textarea
        onChange={handleChange}
        className={`mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ${className}`}
        {...rest}
        ref={textAreaRef}
        rows={1}
        value={value}
      />
    </label>
  );
}
