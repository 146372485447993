import { useState } from "react";
import { PermissionsProvider } from "../common/PermissionsProvider";
import { CSVLink } from "react-csv";
import { useLocation, useParams } from "react-router-dom";
import {
  AfriexActions,
  AfriexPermissions,
  AfriexTransactionStatus,
} from "../../types";
import { PlusIcon } from "@heroicons/react/outline";
import { Filter } from "../Filter";
import {
  getTransactionChannelsOptions,
  getTransactionProcessorsOptions,
  getTransactionStatusOptions,
  getTransactionTypesOptions,
} from "../../constants/formatters";
import { CustomInput, CustomSelect, DatePicker, Button } from "..";
import { TransactionFiltersProps } from "../../screens/Transaction/List/types";

export default function TransactionFilters({
  transactions,
  filterCount,
  currentFilters,
  filtersToApply,
  clearFilters,
  selectAppliedFilters,
  handleApplyFilters,
  selectCurrentFilters,
}: TransactionFiltersProps): JSX.Element {
  const { userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("status") as AfriexTransactionStatus;
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const fromDate = filtersToApply.fromDate
    ? new Date(filtersToApply.fromDate)
    : undefined;

  const toDate = filtersToApply.toDate
    ? new Date(filtersToApply.toDate)
    : undefined;

  const handleShowFilterChange = (): void => {
    setShowFilters(!showFilters);
  };

  const handleApplyFilter = (): void => {
    handleApplyFilters();
    setShowFilters(false);
  };

  const handleClearFilters = (): void => {
    clearFilters();
  };

  return (
    <>
      <div className="mt-5 w-full">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            {/* Filter Box */}
            <Filter
              closeFilter={() => setShowFilters(false)}
              handleShowFilter={handleShowFilterChange}
              label="Filters"
            >
              {/* Filter Body */}
              {showFilters && (
                <div className="p-4 space-y-4 max-w-4xl mx-auto w-600">
                  <div className="flex flex-col gap-4">
                    {/* First Row */}
                    <div className="flex items-center gap-2">
                      <CustomInput
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          selectAppliedFilters("transactionId", e.target.value)
                        }
                        name="transactionId"
                        label="Transaction ID"
                        type="text"
                        value={filtersToApply.transactionId ?? ""}
                        className="w-full isFull"
                        placeholder="Enter Transaction ID"
                      />
                      {!userId && (
                        <CustomInput
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            selectAppliedFilters("userId", e.target.value)
                          }
                          name="userId"
                          label="User ID"
                          type="text"
                          value={filtersToApply.userId ?? ""}
                          className="w-full isFull"
                          placeholder="Enter User ID"
                        />
                      )}
                    </div>

                    {/* Second Row */}
                    <div className="flex items-center gap-2">
                      <DatePicker
                        label="From Date"
                        selectedDate={fromDate}
                        setSelectedDate={(date) =>
                          selectAppliedFilters("fromDate", date)
                        }
                        placeholderText="Select Date"
                      />

                      <DatePicker
                        label="To Date"
                        selectedDate={toDate}
                        setSelectedDate={(date) =>
                          selectAppliedFilters("toDate", date)
                        }
                        placeholderText="Select Date"
                      />

                      <CustomSelect
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          selectAppliedFilters("channel", e.target.value)
                        }
                        value={filtersToApply.channel}
                        name="channel"
                        options={getTransactionChannelsOptions()}
                        placeholder="Select"
                        label="Payment Method"
                      />
                    </div>

                    {/* Third Row */}
                    <div className="flex items-center gap-2">
                      <CustomSelect
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          selectAppliedFilters("type", e.target.value)
                        }
                        value={filtersToApply.type}
                        name="type"
                        options={getTransactionTypesOptions()}
                        placeholder="Select"
                        label="Filter by Type"
                      />
                      <CustomSelect
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                          selectAppliedFilters("processor", e.target.value)
                        }
                        value={filtersToApply.processor}
                        name="processor"
                        options={getTransactionProcessorsOptions()}
                        placeholder="Select"
                        label="Filter by Processor"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <Button
                      onClick={handleClearFilters}
                      colorScheme="cyan"
                      variant="ghost"
                    >
                      Clear
                    </Button>

                    <Button
                      onClick={handleApplyFilter}
                      colorScheme="cyan"
                      disabled={filterCount === 0}
                      className="ml-3"
                    >
                      Apply Filters {filterCount && ` (${filterCount})`}
                    </Button>
                  </div>
                </div>
              )}
            </Filter>
          </div>

          {/* Status Select Box */}
          <div className="flex items-center space-x-3">
            {!status && (
              <CustomSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  selectCurrentFilters("status", e.target.value)
                }
                value={currentFilters.status}
                name="status"
                options={getTransactionStatusOptions()}
                placeholder="Filter by Status"
              />
            )}

            <PermissionsProvider
              permission={AfriexPermissions.TRANSACTIONS_PAGE}
              action={AfriexActions.CLICK_TRANSACTION_EXPORT}
            >
              <Button
                colorScheme="cyan"
                leftIcon={<PlusIcon className="h-5 w-5" />}
              >
                <CSVLink data={transactions}>Export as .CSV</CSVLink>
              </Button>
            </PermissionsProvider>
          </div>
        </div>
      </div>
    </>
  );
}
