import { useEffect, useState } from "react";
import { CurrencyPicker } from "../../../components";
import { AfriexRate, SupportedCurrencies } from "../../../types";
import { formatRate } from "../../../utils/formatRate";
import { applyDiscountToRates } from "../../../utils/rates/applyDiscountToRates";
import { convertRatesListToMap } from "../../../utils/rates/convertRatesListToMap";

type Props = {
  rates: AfriexRate[];
  discount: number;
  targetCurrency?: SupportedCurrencies;
};
const RateWidget = ({ rates, discount, targetCurrency }: Props) => {
  const [fromCurrencyCode, setFromCurrencyCode] = useState(
    SupportedCurrencies.USD
  );
  const [toCurrencyCode, setToCurrencyCode] = useState(SupportedCurrencies.NGN);
  useEffect(() => {
    if (!rates) return;
    if (targetCurrency) {
      setToCurrencyCode(targetCurrency);
    }
  }, [fromCurrencyCode, toCurrencyCode, discount, targetCurrency, rates]);
  const ratesMap = convertRatesListToMap(rates) as any;
  const ratesMapWithDiscount = convertRatesListToMap(
    applyDiscountToRates(rates, discount)
  ) as any;
  const rate = ratesMapWithDiscount?.[fromCurrencyCode]?.[toCurrencyCode] ?? 0;
  const baseRate = ratesMap?.[SupportedCurrencies.USD]?.[toCurrencyCode] ?? 0;
  return (
    <div className="bg-gray-100 p-4 flex flex-col items-baseline">
      <div className="flex gap-3">
        <CurrencyPicker
          label="From"
          value={fromCurrencyCode}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setFromCurrencyCode(e.target.value as SupportedCurrencies)
          }
        />
        <CurrencyPicker
          label="To"
          value={toCurrencyCode}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setToCurrencyCode(e.target.value as SupportedCurrencies)
          }
        />
        <div className="text-sm mt-1">
          <label className="block text-gray-600 text-sm font-medium mb-2">
            Base Rate
          </label>
          <span className="text-sm text-gray-700 font-medium">
            {formatRate(baseRate)}
          </span>
        </div>
      </div>

      <p className="mt-2 text-xs text-blue-600 font-medium">
        Rate with Discount:{" "}
        <span className="text-sm text-gray-700 font-medium">
          {formatRate(rate)}
        </span>
      </p>
    </div>
  );
};

export default RateWidget;
