const countries: { [key: string]: string } = {
  AE: "🇦🇪",
  AT: "🇦🇺",
  AU: "🇦🇺",
  BE: "🇧🇪",
  BJ: "🇧🇯",
  CA: "🇨🇦",
  CH: "🇨🇭",
  CI: "🇨🇮",
  CM: "🇨🇲",
  CN: "🇨🇳",
  DE: "🇩🇪",
  DK: "🇩🇰",
  EG: "🇪🇬",
  ES: "🇪🇸",
  ET: "🇪🇹",
  EU: "🇪🇺",
  FR: "🇫🇷",
  GB: "🇬🇧",
  GH: "🇬🇭",
  GR: "🇬🇷",
  HT: "🇭🇹",
  ID: "🇮🇩",
  IE: "🇮🇪",
  IN: "🇮🇳",
  IT: "🇮🇹",
  KE: "🇰🇪",
  NG: "🇳🇬",
  NL: "🇳🇱",
  MT: "🇲🇹",
  MX: "🇲🇽",
  PH: "🇵🇭",
  PT: "🇵🇹",
  PK: "🇵🇰",
  RO: "🇷🇴",
  RU: "🇷🇺",
  SA: "🇸🇦",
  SE: "🇸🇪",
  SI: "🇸🇮",
  SG: "🇸🇬",
  TG: "🇹🇬",
  TH: "🇹🇭",
  TZ: "🇹🇿",
  UA: "🇺🇦",
  UG: "🇺🇬",
  US: "🇺🇸",
  ZA: "🇿🇦",
  ZM: "🇿🇲",
};

export const currencyToCountry: {
  [key: string]: string;
} = {
  USD: "US", // United States
  EUR: "EU", // European Union
  NGN: "NG", // Nigeria
  CAD: "CA", // Canada
  GBP: "GB", // United Kingdom
  HTG: "HT", // Haiti
  EGP: "EG", // Egypt
  KES: "KE", // Kenya
  XAF: "CM", // Central African CFA franc (Cameroon)
  XOF: "SN", // West African CFA franc (Senegal)
  UGX: "UG", // Uganda
  ETB: "ET", // Ethiopia
  PKR: "PK", // Pakistan
  AED: "AE", // United Arab Emirates
  AUD: "AU", // Australia
  BHD: "BH", // Bahrain
  CHF: "CH", // Switzerland
  CNY: "CN", // China
  DKK: "DK", // Denmark
  HKD: "HK", // Hong Kong
  INR: "IN", // India
  JPY: "JP", // Japan
  MXN: "MX", // Mexico
  NOK: "NO", // Norway
  RUB: "RU", // Russia
  SAR: "SA", // Saudi Arabia
  SEK: "SE", // Sweden
  SGD: "SG", // Singapore
  ZAR: "ZA", // South Africa
  GHS: "GH", // Ghana
  BEF: "BE", // Belgium
  BJX: "BJ", // Benin
  CDF: "CI", // Ivory Coast
  ZMW: "ZM", // Zambia
  ESP: "ES", // Spain
  MTN: "MT", // Malta
  PHP: "PH", // Philippines
  PTG: "PT", // Portugal
  RON: "RO", // Romania
  GRD: "GR", // Greece
  IDR: "ID", // Indonesia
  IEP: "IE", // Ireland
  ITL: "IT", // Italy
  TZS: "TZ", // Tanzania
  THB: "TH", // Thailand
  UAH: "UA", // Ukraine
  TGX: "TG", // Togo
};
export const euroCountries = ["DE", "FR", "IT", "ES", "PT"];
export const stripeCountries = ["CA", "US", "GB"];
export const mapCountryCodeToFlag = (countryCode: string) => {
  return countries?.[countryCode] ?? "";
};

export default countries;
