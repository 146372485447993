import { useState } from "react";
import { Button } from "../../../components";
import { AfriexPerson } from "../../../types";
import UploadKYCModal from "./UploadKYCModal";

type KYCUpdateButtonProps = {
  user: AfriexPerson;
  isKYCUploaded?: boolean;
};
const KYCUpdateButton = ({
  user,
  isKYCUploaded = false,
}: KYCUpdateButtonProps) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const updateLabel = isKYCUploaded ? "Update Info" : "Upload KYC";
  const toggleFullScreen = () => setShowFullScreen(!showFullScreen);

  return showFullScreen ? (
    <UploadKYCModal
      isOpen={showFullScreen}
      user={user}
      onClose={toggleFullScreen}
    />
  ) : (
    <Button colorScheme="cyan" variant="outline" onClick={toggleFullScreen}>
      {updateLabel}
    </Button>
  );
};

export default KYCUpdateButton;
